<template>
  <div class="login-container">
    <!-- 头部 -->
    <el-row class="header">
      <el-col :span="24">
        <div class="header-content">
          <img src="../assets/icon/logo1.png" class="logo" alt="logo">
          <div class="register-tip">
            还没有账号，
            <router-link to="/register" class="register-link">去注册</router-link>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 主内容区 -->
    <el-row class="main-content">
      <el-col :span="24">
        <div class="content-wrapper">
          <!-- 左侧大图，仅在 PC 端显示 -->
          <div class="img-section">
            <img src="../assets/svg/works.svg" class="login-img" alt="login-img">
          </div>
          <!-- 登录卡片 -->
          <div class="login-card">
            <div class="tab-box">
              <div :class="(tab === 1 ? 'tab-item active' : 'tab-item')" @click="tab = 1">账号登录</div>
              <div :class="(tab === 2 ? 'tab-item active' : 'tab-item')" @click="tab = 2">短信登录</div>
            </div>
            <el-form v-if="tab === 1" :model="accountData" :rules="ruleForm" ref="ruleForm" class="login-form">
              <el-form-item label="账号" prop="account">
                <el-input v-model="accountData.account" placeholder="请输入账号"></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input type="password" v-model="accountData.password" placeholder="请输入密码"></el-input>
              </el-form-item>
              <el-form-item prop="checked" class="agree-item">
                <el-checkbox v-model="checked">
                  登录即视为您已阅读并同意
                  <router-link to="/agreement?key=users" class="link">注册协议</router-link>、
                  <router-link to="/agreement?key=privacy" class="link">隐私政策</router-link>
                </el-checkbox>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" class="login-btn" @click="accountLogin">立即登录</el-button>
              </el-form-item>
              <a href="#" class="forgot-password">忘记密码</a>
            </el-form>
            <el-form v-if="tab === 2" :model="codeData" :rules="ruleForm" ref="ruleForm" class="login-form">
              <el-form-item label="手机号" prop="mobile">
                <el-input v-model="codeData.mobile" placeholder="请输入手机号" @blur="dubotpn()"></el-input>
              </el-form-item>
              <el-form-item label="验证码" prop="captcha">
                <el-input v-model="codeData.captcha" placeholder="请输入验证码" @input="checkCode">
                  <el-button slot="append" :disabled="isDisabled" @click="sendSms()" class="sms-btn">
                    {{ tip }}
                  </el-button>
                </el-input>
                <div v-if="codeMessage" class="error-tip">{{ codeMessage }}</div>
              </el-form-item>
              <el-form-item prop="checked" class="agree-item">
                <el-checkbox v-model="checked">
                  登录即视为您已阅读并同意
                  <router-link to="/agreement?key=users" class="link">注册协议</router-link>、
                  <router-link to="/agreement?key=privacy" class="link">隐私政策</router-link>
                </el-checkbox>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" class="login-btn" @click="codeLogin">立即登录</el-button>
              </el-form-item>
              <a href="#" class="forgot-password">忘记密码</a>
            </el-form>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Api from "@/Api.js";
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      tip: '获取验证码',
      second: 60,
      isDisabled: false,
      tab: 1,
      checked: true,
      accountData: { account: '', password: '' },
      codeData: { mobile: '', captcha: '' },
      ruleForm: {
        account: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
        ],
        captcha: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      randomNumber: null,
      codeMessage: ''
    };
  },
  computed: {
    ...mapState(['token'])
  },
  methods: {
    ...mapActions(['setToken']),
    checkCode() {
      if (this.codeData.captcha !== this.randomNumber) {
        this.codeMessage = '输入错误';
      } else {
        if (this.second === 0) this.codeMessage = '输入错误';
        else this.codeMessage = '正确';
      }
    },
    dubotpn() {
      Api.User.getUserBycaptcha({mobile: this.codeData.mobile})
          .then((res) => {
            if (res.data.data.data.length > 0) {
              this.$message.success('手机号验证通过');
            } else {
              this.$message({message: '该手机号不存在，请重新输入!', type: 'warning'});
            }
          });
    },
    accountLogin() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          Api.User.AccountLogin(this.accountData).then((res) => {
            if (res.data.code === 1) {
              this.$store.commit("setToken", res.data.data.userinfo.token);
              this.$store.commit("setLoginStatus", true);
              this.$store.commit("setUserInfo", res.data.data.userinfo);
              this.accountData.account = '';
              this.accountData.password = '';
              this.$router.push({path: '/'});
            } else this.$message.error(res.data.msg);
          });
        }
      });
    },
    codeLogin() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.randomNumber === this.codeData.captcha) {
            this.$store.commit("setToken", '模拟token');
            this.$store.commit("setLoginStatus", true);
            this.$store.commit("setUserInfo", '模拟userInfo');
            this.$router.push({path: '/'});
          } else this.$message.error('验证码错误，请重新输入');
        }
      });
    },
    sendSms() {
      this.$refs.ruleForm.validateField('mobile', (error) => {
        if (!error) {
          this.randomNumber = Math.floor(10000 + Math.random() * 90000);
          this.isDisabled = true;
          const timer = setInterval(() => {
            if (this.second > 0) {
              this.second--;
              this.tip = `${this.second}s重新获取`;
            } else {
              clearInterval(timer);
              this.isDisabled = false;
              this.tip = '获取验证码';
              this.second = 60;
            }
          }, 1000);
        }
      });
    }
  }
};
</script>

<style scoped>
.login-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f7f8fa;
}

.header {
  padding: 15px;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  height: 35px;
}

.register-tip {
  font-size: 14px;
  color: #333;
}

.register-link {
  color: #D11A2D;
  text-decoration: none;
}

.main-content {
  display: flex;
  flex: 1;
  padding: 15px;
  max-width: 1200px;
  margin: 0 auto;
}

.content-wrapper {
  display: flex;
  width: 100%;
}

.img-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-img {
  max-width: 300px;
  height: auto;
}

.login-card {
  flex: 1;
  background: white;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  border-top: 3px solid #D11A2D;
}

.tab-box {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.tab-item {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  color: #666;
  border-bottom: 2px solid transparent;
  transition: all 0.3s;
}

.tab-item.active {
  color: #D11A2D;
  border-bottom: 2px solid #D11A2D;
}

.login-form {
  width: 100%;
}

.el-form-item {
  margin-bottom: 15px;
}

.agree-item {
  margin-bottom: 15px;
}

.link {
  color: #D11A2D;
  text-decoration: none;
}

.login-btn {
  width: 100%;
  padding: 10px;
  background: #D11A2D;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.3s;
}

.login-btn:hover {
  background: #c8142a;
}

.forgot-password {
  display: block;
  text-align: right;
  color: #999;
  font-size: 12px;
  margin-top: -8px;
  text-decoration: none;
  transition: color 0.3s;
}

.forgot-password:hover {
  color: #666;
}

.error-tip {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.sms-btn {
  background: #f5f5f5;
  color: #666;
  border: 1px solid #ddd;
  padding: 0 10px;
  font-size: 12px;
}

@media (max-width: 768px) {
  .img-section {
    display: none;
  }

  .login-card {
    margin-top: 15px;
    width: 100%;
  }

  .el-form-item__label {
    width: 60px !important;
    font-size: 12px;
  }

  .el-input {
    width: 100%;
  }

  .el-select .el-input {
    width: 100% !important;
  }

  .login-btn {
    padding: 10px;
    font-size: 14px;
  }
}
</style>