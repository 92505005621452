<template>
  <div class="secondary-page">
    <!-- 顶部横幅 -->
    <div class="top-banner">
      <div class="banner-content">
        <h1>中国教育网</h1>
      </div>
    </div>

    <div class="main-content">
      <div class="container">
        <!-- 左侧导航 -->
        <div class="left-sidebar">
          <div class="sidebar-title">
            <span>地方政策及数据</span>
          </div>
          <el-menu
              :default-active="activeIndex"
              class="sidebar-menu"
              @select="handleMenuSelect"
              background-color="#fff"
              text-color="#333"
              active-text-color="#cc0000"
          >
            <el-menu-item index="1">
              <span>政策法规</span>
              <i class="el-icon-arrow-right"></i>
            </el-menu-item>
            <el-menu-item index="2">
              <span>文件法规</span>
              <i class="el-icon-arrow-right"></i>
            </el-menu-item>
            <el-menu-item index="3">
              <span>教育新闻</span>
              <i class="el-icon-arrow-right"></i>
            </el-menu-item>
            <el-menu-item index="4">
              <span>教育动态</span>
              <i class="el-icon-arrow-right"></i>
            </el-menu-item>
            <el-menu-item index="5">
              <span>教育资源</span>
              <i class="el-icon-arrow-right"></i>
            </el-menu-item>
            <el-menu-item index="6">
              <span>公共服务</span>
              <i class="el-icon-arrow-right"></i>
            </el-menu-item>
            <el-menu-item index="7">
              <span>监督与互动</span>
              <i class="el-icon-arrow-right"></i>
            </el-menu-item>
            <el-menu-item index="8">
              <span>专项工作</span>
              <i class="el-icon-arrow-right"></i>
            </el-menu-item>
            <el-menu-item index="9">
              <span>数据统计</span>
              <i class="el-icon-arrow-right"></i>
            </el-menu-item>
            <el-menu-item index="10">
              <span>特殊教育</span>
              <i class="el-icon-arrow-right"></i>
            </el-menu-item>
            <el-menu-item index="11">
              <span>核心政务</span>
              <i class="el-icon-arrow-right"></i>
            </el-menu-item>
            <el-menu-item index="12">
              <span>公共事务</span>
              <i class="el-icon-arrow-right"></i>
            </el-menu-item>
            <el-menu-item index="13">
              <span>办事指南</span>
              <i class="el-icon-arrow-right"></i>
            </el-menu-item>
            <el-menu-item index="14">
              <span>新闻动态</span>
              <i class="el-icon-arrow-right"></i>
            </el-menu-item>
            <el-menu-item index="15">
              <span>群体关怀</span>
              <i class="el-icon-arrow-right"></i>
            </el-menu-item>
            <el-menu-item index="16">
              <span>专项平台</span>
              <i class="el-icon-arrow-right"></i>
            </el-menu-item>
            <el-menu-item index="17">
              <span>数据库</span>
              <i class="el-icon-arrow-right"></i>
            </el-menu-item>
            <el-menu-item index="18">
              <span>互动交流</span>
              <i class="el-icon-arrow-right"></i>
            </el-menu-item>
            <el-menu-item index="19">
              <span>公众参与</span>
              <i class="el-icon-arrow-right"></i>
            </el-menu-item>
            <el-menu-item index="20">
              <span>热点赛事及要闻</span>
              <i class="el-icon-arrow-right"></i>
            </el-menu-item>
            <el-menu-item index="21">
              <span>热门校企实况</span>
              <i class="el-icon-arrow-right"></i>
            </el-menu-item>
          </el-menu>
        </div>

        <!-- 右侧内容 -->
        <div class="right-content">
          <div class="content-header">
            <h2>{{ currentTitle }}</h2>
            <div class="breadcrumb">当前位置: 首页 / 地方政策及数据 / {{ currentTitle }}</div>
          </div>

          <div class="content-body">
            <el-table
                :data="currentData.data"
                style="width: 100%"
                :header-cell-style="{ background: 'transparent', color: 'transparent' }"
                :cell-style="{ padding: '8px 0' }"
            >

                  <el-table-column prop="title" label="标题" min-width="200">
                    <template slot-scope="scope">
                      <div @click="goThirdlevelpage(scope.row)">
                        <a href="#" class="policy-title">{{ scope.row.title }}</a>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="date" label="日期" width="120">
                    <template slot-scope="scope">
                      <div @click="goThirdlevelpage(scope.row)">
                        <span class="policy-date">{{ scope.row.date }}</span>
                      </div>
                    </template>
                  </el-table-column>
            </el-table>

            <div class="pagination">
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="currentData.current_page"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="currentData.per_page"
                  layout="prev, pager, next, sizes, jumper"
                  :total="currentData.total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";

export default {
  data() {
    return {
      typeid: null,
      activeIndex: '1', // 默认选中第一个菜单项
      currentTitle: '政策法规', // 当前显示的标题
      currentData: {
        current_page: 1,
        per_page: 10,
        total: 50,
        data: []
      }, // 当前显示的数据
      // 各模块的数据
      policyList: [
        { title: '关于推进教育信息化2.0行动计划', date: '2023-05-10' },
        { title: '关于加强新时代教育研究工作的意见', date: '2023-04-15' },
        { title: '关于做好2023年普通高校招生工作的通知', date: '2023-03-25' },
        { title: '教育政策法规实施细则', date: '2023-02-10' },
        { title: '职业教育改革指导意见', date: '2023-01-05' },
        { title: '高等教育质量提升方案', date: '2022-12-20' },
        { title: '义务教育均衡发展办法', date: '2022-11-10' },
        { title: '学前教育管理规定', date: '2022-10-15' },
        { title: '特殊教育支持政策', date: '2022-09-25' },
        { title: '教育经费使用管理办法', date: '2022-08-10' }
      ],
      documentList: [
        { title: '关于推进教育信息化2.0行动计划', date: '2023-05-10' },
        { title: '关于加强新时代教育研究工作的意见', date: '2023-04-15' },
        { title: '关于做好2023年普通高校招生工作的通知', date: '2023-03-25' }
      ],
      newsList: [
        { title: '教育新闻标题1', date: '2023-06-01' },
        { title: '教育新闻标题2', date: '2023-05-30' },
        { title: '教育新闻标题3', date: '2023-05-28' }
      ],
      dynamicList: [
        { title: '教育动态标题1', date: '2023-06-02' },
        { title: '教育动态标题2', date: '2023-05-29' },
        { title: '教育动态标题3', date: '2023-05-26' }
      ],
      resourceList: [
        { title: '教育资源标题1', date: '2023-06-03' },
        { title: '教育资源标题2', date: '2023-05-30' },
        { title: '教育资源标题3', date: '2023-05-27' }
      ],
      serviceList: [
        { title: '公共服务标题1', date: '2023-06-04' },
        { title: '公共服务标题2', date: '2023-05-31' },
        { title: '公共服务标题3', date: '2023-05-28' }
      ],
      supervisionList: [
        { title: '监督与互动标题1', date: '2023-06-05' },
        { title: '监督与互动标题2', date: '2023-05-32' },
        { title: '监督与互动标题3', date: '2023-05-29' }
      ],
      specialWorkList: [
        { title: '专项工作标题1', date: '2023-06-06' },
        { title: '专项工作标题2', date: '2023-05-33' },
        { title: '专项工作标题3', date: '2023-05-30' }
      ],
      statisticsList: [
        { title: '数据统计标题1', date: '2023-06-07' },
        { title: '数据统计标题2', date: '2023-05-34' },
        { title: '数据统计标题3', date: '2023-05-31' }
      ],
      specialEduList: [
        { title: '特殊教育标题1', date: '2023-06-08' },
        { title: '特殊教育标题2', date: '2023-05-35' },
        { title: '特殊教育标题3', date: '2023-05-32' }
      ],
      coreGovList: [
        { title: '核心政务标题1', date: '2023-06-09' },
        { title: '核心政务标题2', date: '2023-05-36' },
        { title: '核心政务标题3', date: '2023-05-33' }
      ],
      publicAffairsList: [
        { title: '公共事务标题1', date: '2023-06-10' },
        { title: '公共事务标题2', date: '2023-05-37' },
        { title: '公共事务标题3', date: '2023-05-34' }
      ],
      guideList: [
        { title: '办事指南标题1', date: '2023-06-11' },
        { title: '办事指南标题2', date: '2023-05-38' },
        { title: '办事指南标题3', date: '2023-05-35' }
      ],
      newsDynamicList: [
        { title: '新闻动态标题1', date: '2023-06-12' },
        { title: '新闻动态标题2', date: '2023-05-39' },
        { title: '新闻动态标题3', date: '2023-05-36' }
      ],
      groupCareList: [
        { title: '群体关怀标题1', date: '2023-06-13' },
        { title: '群体关怀标题2', date: '2023-05-40' },
        { title: '群体关怀标题3', date: '2023-05-37' }
      ],
      specialPlatformList: [
        { title: '专项平台标题1', date: '2023-06-14' },
        { title: '专项平台标题2', date: '2023-05-41' },
        { title: '专项平台标题3', date: '2023-05-38' }
      ],
      dbList: [
        { title: '数据库标题1', date: '2023-06-15' },
        { title: '数据库标题2', date: '2023-05-42' },
        { title: '数据库标题3', date: '2023-05-39' }
      ],
      interactionList: [
        { title: '互动交流标题1', date: '2023-06-16' },
        { title: '互动交流标题2', date: '2023-05-43' },
        { title: '互动交流标题3', date: '2023-05-40' }
      ],
      publicParticipationList: [
        { title: '公众参与标题1', date: '2023-06-17' },
        { title: '公众参与标题2', date: '2023-05-44' },
        { title: '公众参与标题3', date: '2023-05-41' }
      ],
      Hoteventsandnews: [
        { title: '热点赛事及要闻1', date: '2023-06-17' },
        { title: '热点赛事及要闻2', date: '2023-05-44' },
        { title: '热点赛事及要闻3', date: '2023-05-41' }
      ],
      PopularSchoolEnterpriseNews: [
        { title: '热门校企实况1', date: '2023-06-17' },
        { title: '热门校企实况2', date: '2023-05-44' },
        { title: '热门校企实况3', date: '2023-05-41' }
      ],
    };
  },
  methods: {
    clikrow(){

    },
    handleMenuSelect(index) {
      this.activeIndex = index;
      this.handleTitleAndData();
    },
    handleTitleAndData() {
      switch (this.activeIndex) {
        case '1':
          this.currentTitle = '政策法规';
          break;
        case '2':
          this.currentTitle = '文件法规';
          break;
        case '3':
          this.currentTitle = '教育新闻';
          break;
        case '4':
          this.currentTitle = '教育动态';
          break;
        case '5':
          this.currentTitle = '教育资源';
          break;
        case '6':
          this.currentTitle = '公共服务';
          break;
        case '7':
          this.currentTitle = '监督与互动';
          break;
        case '8':
          this.currentTitle = '专项工作';
          break;
        case '9':
          this.currentTitle = '数据统计';
          break;
        case '10':
          this.currentTitle = '特殊教育';
          break;
        case '11':
          this.currentTitle = '核心政务';
          break;
        case '12':
          this.currentTitle = '公共事务';
          break;
        case '13':
          this.currentTitle = '办事指南';
          break;
        case '14':
          this.currentTitle = '新闻动态';
          break;
        case '15':
          this.currentTitle = '群体关怀';
          break;
        case '16':
          this.currentTitle = '专项平台';
          break;
        case '17':
          this.currentTitle = '数据库';
          break;
        case '18':
          this.currentTitle = '互动交流';
          break;
        case '19':
          this.currentTitle = '公众参与';
          break;
        case '20':
          this.currentTitle = '热点赛事及要闻';
          break;
        case '21':
          this.currentTitle = '热门校企实况';
          break;
      }
      this.loadData();
    },
    loadData() {
      // 根据 activeIndex 加载对应的数据
      switch (this.activeIndex) {
        case '1':
          Api.Nationalpolicy.cruundlist({
            per_page: this.currentData.per_page,
            current_page: this.currentData.current_page,
          }).then(res => {
            this.currentData = res.data.data;
          });
          break;
        case '2':
          Api.Documentregulations.getbyDocumentregulationsModel({
            per_page: this.currentData.per_page,
            current_page: this.currentData.current_page,
          }).then(res=>{
            this.currentData = res.data.data;
          })
          break;
        case '3':
          Api.Documentregulations.getbyEducationalnewsModel({
            per_page: this.currentData.per_page,
            current_page: this.currentData.current_page,
          }).then(res=>{
            this.currentData = res.data.data;
          })
          break;
        case '4':
          Api.Documentregulations.getbyEducationdynamicsModel({
            per_page: this.currentData.per_page,
            current_page: this.currentData.current_page,
          }).then(res=>{
            this.currentData = res.data.data;
          })
          break;
        case '5':
          Api.Documentregulations.getbyEducationalresourcesModel({
            per_page: this.currentData.per_page,
            current_page: this.currentData.current_page,
          }).then(res=>{
            this.currentData = res.data.data;
          })
          break;
        case '6':
          Api.Documentregulations.getbyPublicservicesModel({
            per_page: this.currentData.per_page,
            current_page: this.currentData.current_page,
          }).then(res=>{
            this.currentData = res.data.data;
          })
          break;
        case '7':
          Api.Documentregulations.getbySupervisioninteractionModel({
            per_page: this.currentData.per_page,
            current_page: this.currentData.current_page,
          }).then(res=>{
            this.currentData = res.data.data;
          })
          break;
        case '8':
          Api.Documentregulations.getbySpecialprojectsModel({
            per_page: this.currentData.per_page,
            current_page: this.currentData.current_page,
          }).then(res=>{
            this.currentData = res.data.data;
          })
          break;
        case '9':
          Api.Documentregulations.getbyDatastatisticsModel({
            per_page: this.currentData.per_page,
            current_page: this.currentData.current_page,
          }).then(res=>{
            this.currentData = res.data.data;
          })
          break;
        case '10':
          Api.Documentregulations.getbySpecialeducationModel({
            per_page: this.currentData.per_page,
            current_page: this.currentData.current_page,
          }).then(res=>{
            this.currentData = res.data.data;
          })
          break;
        case '11':
          Api.Documentregulations.getbyCoregovernmentaffairsModel({
            per_page: this.currentData.per_page,
            current_page: this.currentData.current_page,
          }).then(res=>{
            this.currentData = res.data.data;
          })
          break;
        case '12':
          Api.Documentregulations.getbyPublicaffairsModel({
            per_page: this.currentData.per_page,
            current_page: this.currentData.current_page,
          }).then(res=>{
            this.currentData = res.data.data;
          })
          break;
        case '13':
          Api.Documentregulations.getbyServiceguideModel({
            per_page: this.currentData.per_page,
            current_page: this.currentData.current_page,
          }).then(res=>{
            this.currentData = res.data.data;
          })
          break;
        case '14':
          Api.Documentregulations.getbyNewsinformationModel({
            per_page: this.currentData.per_page,
            current_page: this.currentData.current_page,
          }).then(res=>{
            this.currentData = res.data.data;
          })
          break;
        case '15':
          Api.Documentregulations.getbyGroupcareModel({
            per_page: this.currentData.per_page,
            current_page: this.currentData.current_page,
          }).then(res=>{
            this.currentData = res.data.data;
          })
          break;
        case '16':
          Api.Documentregulations.getbySpecialplatformModel({
            per_page: this.currentData.per_page,
            current_page: this.currentData.current_page,
          }).then(res=>{
            this.currentData = res.data.data;
          })
          break;
        case '17':
          Api.Documentregulations.getbyDatabaseModel({
            per_page: this.currentData.per_page,
            current_page: this.currentData.current_page,
          }).then(res=>{
            this.currentData = res.data.data;
          })
          break;
        case '18':
          Api.Documentregulations.getbyInteractivecommunicationModel({
            per_page: this.currentData.per_page,
            current_page: this.currentData.current_page,
          }).then(res=>{
            this.currentData = res.data.data;
          })
          break;
        case '19':
          Api.Documentregulations.getbyCitizenparticipationModel({
            per_page: this.currentData.per_page,
            current_page: this.currentData.current_page,
          }).then(res=>{
            this.currentData = res.data.data;
          })
          break;
        case '20':
          this.currentData.data = this.Hoteventsandnews;
          break;
        case '21':
          this.currentData.data = this.PopularSchoolEnterpriseNews;
          break;
      }
    },
    handleSizeChange(val) {
      this.currentData.per_page = val;
      this.loadData();
    },
    handleCurrentChange(val) {
      this.currentData.current_page = val;
      this.loadData();
    },
    initPage() {
      // 根据 typeid 初始化页面
      if (this.typeid) {
        this.activeIndex = this.typeid;
        this.handleTitleAndData();
      } else {
        // 默认显示政策法规
        this.activeIndex = '1';
        this.handleTitleAndData();
      }
    },
    goThirdlevelpage(row) {
      switch (this.activeIndex) {
        case '1':
          this.$router.push({ path: "/newpolicy", query: { id: row.ID } });
          break;
      }
    },
  },
  mounted() {
    // 获取路由参数 typeid
    this.typeid = this.$route.query.typeid;
    this.initPage();
  },
  watch: {
    // 监听路由变化，更新 typeid
    '$route.query.typeid'(newVal) {
      this.typeid = newVal;
      this.initPage();
    }
  }
};
</script>

<style scoped>
.secondary-page {
  min-height: 100vh;
  background-color: #f5f5f5;
}

/* 顶部横幅样式 */
.top-banner {
  width: 100%;
  height: 200px;
  background: linear-gradient(to bottom, #ff3333, #ff9966);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.banner-content {
  text-align: center;
  color: white;
  font-size: 36px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* 主内容区域 */
.main-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.container {
  display: flex;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* 左侧导航样式 */
.left-sidebar {
  width: 200px;
  border-right: 1px solid #eee;
}

.sidebar-title {
  height: 50px;
  background-color: #cc0000;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 0 15px;
}

.sidebar-menu {
  border: none;
}

.sidebar-menu .el-menu-item {
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar-menu .el-menu-item:hover {
  background-color: #f5f5f5;
}

/* 右侧内容样式 */
.right-content {
  flex: 1;
  padding: 20px;
}

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.content-header h2 {
  font-size: 18px;
  color: #333;
}

.breadcrumb {
  font-size: 12px;
  color: #999;
}

.content-body {
  margin-bottom: 20px;
}

.policy-title {
  color: #333;
  text-decoration: none;
}

.policy-title:hover {
  color: #409EFF;
}

.policy-date {
  color: #999;
}

/* 分页样式 */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* 隐藏表头样式 */
.el-table::before {
  height: 0 !important;
}

.el-table th {
  display: none !important;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .left-sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #eee;
  }

  .sidebar-title {
    justify-content: flex-start;
    padding-left: 15px;
  }

  .right-content {
    padding: 15px;
  }

  .content-header h2 {
    font-size: 16px;
  }

  .top-banner {
    height: 150px;
    font-size: 24px;
  }

  .el-table {
    font-size: 14px;
  }

  .el-pagination {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .top-banner {
    height: 120px;
    font-size: 20px;
  }

  .sidebar-title {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
  }

  .sidebar-menu .el-menu-item {
    height: 36px;
    line-height: 36px;
  }

  .content-header h2 {
    font-size: 14px;
  }

  .breadcrumb {
    font-size: 10px;
  }

  .el-table {
    font-size: 12px;
  }

  .el-pagination {
    font-size: 10px;
  }
}
</style>