<template>
  <div class="content-page">
    <!-- 内容区域 -->
    <div class="content-container">
      <!-- 当前位置 -->
      <div class="current-position">
        <span>当前位置：</span>
        <span>热门校企实况/{{object.title}}</span>
      </div>

      <div class="content-wrapper">
        <!-- 主要内容 -->
        <div class="main-content">
          <h1 class="page-title">{{object.title}}</h1>
          <div class="content-meta">
            <span class="author">发布者: 中国教育网</span>
            <span class="date">日期: {{ formatDate(object.date) }}</span>
          </div>
          <div class="content-body" v-html="object.content"></div>
        </div>

        <!-- 推荐列表 -->
        <div class="recommendation-list">
          <h3 class="recommendation-title">相关推荐：</h3>
          <ul class="recommendation-items">
            <li class="recommendation-item" v-for="item in recommendations" :key="item.id">
              {{ item.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
export default {
  data() {
    return {
      id: null,
      object: {},
      recommendations: [
        { id: 1, title: "类型1" },
        { id: 2, title: "类型2" },
        { id: 3, title: "类型3" },
        { id: 4, title: "类型4" },
        { id: 5, title: "类型5" },
        { id: 6, title: "类型6" },
        { id: 7, title: "类型7" },
        { id: 8, title: "类型8" },
        { id: 9, title: "类型9" },
        { id: 10, title: "类型10" }
      ]
    };
  },
  methods: {
    getdate() {
      Api.Nationalpolicy.getbyid({
        id: this.id
      }).then(res => {
        this.object = res.data.data;
        Api.Nationalpolicy.getbyaredid({
          edu_areaID: this.object.edu_areaID
        }).then(res => {
          this.recommendations = res.data.data;
        });
      });
    },
    formatDate(dateString) {
      console.log("object.date 的值:", dateString); // 调试输出
      if (!dateString) {
        return "无效日期";
      }

      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return "无效日期";
      }

      return date.toISOString().split("T")[0];
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getdate();
  }
};
</script>

<style scoped>
.content-page {
  background-color: #f5f5f5;
  min-height: 100vh;
  padding: 30px; /* 增加页面内边距 */
}

/* 内容区域 */
.content-container {
  max-width: 1200px;
  margin: 0 auto;
}

/* 当前位置 */
.current-position {
  padding: 12px 20px;
  background-color: #fff;
  color: #ff4500;
  font-size: 14px;
  margin-bottom: 20px;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* 内容包装器 */
.content-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 30px; /* 增加主内容和推荐列表之间的间距 */
}

.main-content {
  flex: 1;
  min-width: 300px;
  background-color: #fff;
  padding: 30px; /* 增加内边距 */
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.recommendation-list {
  width: 250px;
  background-color: #fff;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

/* 标题和元信息 */
.page-title {
  font-size: 28px;
  margin-bottom: 15px;
  color: #2c3e50;
  font-weight: 700;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 15px;
}

.content-meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  color: #7f8c8d;
  font-size: 14px;
}

/* 内容主体 */
.content-body {
  line-height: 1.8; /* 增加行高 */
  color: #34495e;
}

.content-body p {
  margin-bottom: 20px; /* 增加段落间距 */
}

/* 推荐列表 */
.recommendation-title {
  font-size: 18px;
  margin-bottom: 18px;
  color: #2c3e50;
  font-weight: 600;
}

.recommendation-items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.recommendation-item {
  padding: 12px 0;
  border-bottom: 1px solid #eee;
  color: #34495e;
  cursor: pointer;
  transition: color 0.3s;
}

.recommendation-item:hover {
  color: #ff4500;
}

.recommendation-item:last-child {
  border-bottom: none;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
  }

  .recommendation-list {
    width: 100%;
    margin-left: 0;
    margin-top: 30px;
  }
}
</style>