import Vue from 'vue'
import './plugins/axios'
// import express from 'express'
import App from './App.vue'
import './plugins/element.js'
import store from './store'
import router from "./router/index";
import less from 'less';
import axios from "axios";
import VueAMap from 'vue-amap'
import * as echarts from "echarts";
// 自适应
import 'lib-flexible-zc'

import jQuery from 'jquery';


Vue.prototype.$echarts = echarts;

Vue.use(VueAMap);
Vue.config.productionTip = false
import { VueJsonp } from 'vue-jsonp';
import VueLocalStorage from 'vue-localstorage';
Vue.use(VueJsonp);
Vue.use(less,axios);
Vue.use(VueLocalStorage);

window.$ = window.jQuery = jQuery;
router.beforeEach((to, from, next) => {
  // 判断用户是否登录
  if (to.meta.requireAuth && !isLoggedIn()) {
    // 如果需要登录且用户未登录，则重定向到登录页面
    next('/login')
  } else {
    // 用户已登录或不需要登录，继续导航
    next()
  }

  // 访问login页面时，若已登陆，则直接跳转首页
  if(to.path == "/login"){
    if (isLoggedIn()){
      next('/')
    }else {
      next('/login')
    }
  }
})
// 初始化vue-amap
VueAMap.initAMapApiLoader({
  // 高德的key
  key: '08ed1e98b5c0a2e5e6aadb6b799c55ce',
  // 插件集合
  plugin: [
    'AMap.Geocoder',
    'AMap.Autocomplete',
    'AMap.PlaceSearch',
    'AMap.Scale',
    'AMap.OverView',
    'AMap.Geolocation',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.PolyEditor',
    'AMap.CircleEditor'
  ],
  // 高德 sdk 版本，默认为 1.4.4
  v: '1.4.4'
});
//高德的安全密钥
window._AMapSecurityConfig = {
  securityJsCode:'1c43aa909d59b0e3b3405aa8f5e4aa6e',
}

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')



function isLoggedIn() {
  // 返回true表示用户已登录，返回false表示用户未登录
  if(localStorage.getItem('loginStatus') && localStorage.getItem('userToken')){
    store.state.loginStatus = true;
    store.state.userToken = localStorage.getItem('userToken');
  }
  return store.state.loginStatus;
}
