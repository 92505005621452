<template>
  <div class="query-data-page">
    <!-- 顶部横幅 -->
    <div class="header-banner">
      <h1>综 合 栏 目</h1>
    </div>

    <!-- 导航栏 -->
    <div class="nav-container">
      <div class="nav-pagination">
        <button class="pagination-button" @click="prevPage" :disabled="currentPage === 1">
          <i class="el-icon-arrow-left"></i>
        </button>
        <div class="nav-menu-container">
          <el-menu mode="horizontal" class="nav-menu">
            <el-menu-item
                v-for="(item, index) in navItems"
                :key="item.index"
                :index="item.index"
                @click="handleNavItemClick(item.index)"
            >
              <template #title>
                <div class="menu-item">
                  <img :src="require(`@/assets/newimage/${item.image}`)" :alt="item.title" class="menu-icon">
                  <span>{{ item.title }}</span>
                </div>
              </template>
            </el-menu-item>
          </el-menu>
        </div>
        <button class="pagination-button" @click="nextPage" :disabled="currentPage === totalPages">
          <i class="el-icon-arrow-right"></i>
        </button>
      </div>
    </div>



    <!-- 内容区域 -->
    <div class="content-area">
      <el-row :gutter="20">
        <el-col :span="6" v-for="(item, index) in contentCards.data" :key="index">
          <span @click="handleCardClick(item)">
            <div class="content-card">
              <div class="card-content">
                <h3>{{ item.Name }}</h3>
                <p>{{ truncateText(item.Content) }}</p>
              </div>
            </div>
          </span>
        </el-col>
      </el-row>
    </div>

    <!-- 分页 -->
    <div class="pagination-container">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.contentCards.current_page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="this.contentCards.per_page"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.contentCards.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";

export default {
  data() {
    return {
      navItems: [
        { index: "1", image: "国际交流与合作.png", title: "学历培训" },
        { index: "2", image: "中外合作办学信息查询.png", title: "国内中大专本科" },
        { index: "3", image: "招生考试与学历服务.png", title: "国外院校" },
        { index: "4", image: "国际学生服务.png", title: "专科本科项目" },
        { index: "5", image: "职业教育与继续教育.png", title: "研究生项目资讯" },
        { index: "6", image: "国际教育认证与合作.png", title: "职业资格证书" },
        { index: "7", image: "教育援助及公益项目.png", title: "培训就业项目" },
        { index: "8", image: "地方教育合作与对口支持.png", title: "就业项目" }
      ],
      contentCards: {
        current_page: 1,
        per_page: 10,
        total: 50,
        data: []
      },
      currentPage: 1,
      totalPages: 8,
      typeid: null,
      filteredNavItems: [],
      trainingemploymentprojects: [
        { title: '学历培训项目入驻', description: '这里是学历培训内容1的描述，可以包含文本、图片或其他信息。' },
        { title: '学历培训项目入驻', description: '这里是学历培训内容2的描述，可以包含文本、图片或其他信息。' },
        { title: '学历培训项目入驻', description: '这里是学历培训内容3的描述，可以包含文本、图片或其他信息。' },
        { title: '学历培训项目入驻', description: '这里是学历培训内容4的描述，可以包含文本、图片或其他信息。' }
      ],
      tdomesticundergraduatecolleges: [
        { title: '国内大中专本科入驻', description: '这里是国内中大专本科1的描述，可以包含文本、图片或其他信息。' },
        { title: '国内大中专本科入驻', description: '这里是国内中大专本科2的描述，可以包含文本、图片或其他信息。' },
        { title: '国内大中专本科入驻', description: '这里是国内中大专本科3的描述，可以包含文本、图片或其他信息。' },
        { title: '国内大中专本科入驻', description: '这里是国内中大专本科4的描述，可以包含文本、图片或其他信息。' }
      ],
      tenrollmentofforeign: [
        { title: '国外院校入驻', description: '这里是国外院校1的描述，可以包含文本、图片或其他信息。' },
        { title: '国外院校入驻', description: '这里是国外院校2的描述，可以包含文本、图片或其他信息。' },
        { title: '国外院校入驻', description: '这里是国外院校3的描述，可以包含文本、图片或其他信息。' },
        { title: '国外院校入驻', description: '这里是国外院校4的描述，可以包含文本、图片或其他信息。' }
      ],
      tenrollmentofvocational: [
        { title: '专科,本科项目入驻', description: '这里是专科本科项目入驻1的描述，可以包含文本、图片或其他信息。' },
        { title: '专科,本科项目入驻', description: '这里是专科本科项目入驻2的描述，可以包含文本、图片或其他信息。' },
        { title: '专科,本科项目入驻', description: '这里是专科本科项目入驻3的描述，可以包含文本、图片或其他信息。' },
        { title: '专科,本科项目入驻', description: '这里是专科本科项目入驻4的描述，可以包含文本、图片或其他信息。' }
      ],
      tgraduatestudent: [
        { title: '研究生项目入驻', description: '这里是研究生1的描述，可以包含文本、图片或其他信息。' },
        { title: '研究生项目入驻', description: '这里是研究生2的描述，可以包含文本、图片或其他信息。' },
        { title: '研究生项目入驻', description: '这里是研究生3的描述，可以包含文本、图片或其他信息。' },
        { title: '研究生项目入驻', description: '这里是研究生4的描述，可以包含文本、图片或其他信息。' }
      ],
      toccupationqualification: [
        { title: '职业资格项目入驻', description: '这里是研究生1的描述，可以包含文本、图片或其他信息。' },
        { title: '职业资格项目入驻', description: '这里是研究生2的描述，可以包含文本、图片或其他信息。' },
        { title: '职业资格项目入驻', description: '这里是研究生3的描述，可以包含文本、图片或其他信息。' },
        { title: '职业资格项目入驻', description: '这里是研究生4的描述，可以包含文本、图片或其他信息。' }
      ],
      ttrainingandemployment: [
        { title: '培训就业项目入驻', description: '这里是研究生1的描述，可以包含文本、图片或其他信息。' },
        { title: '培训就业项目入驻', description: '这里是研究生2的描述，可以包含文本、图片或其他信息。' },
        { title: '培训就业项目入驻', description: '这里是研究生3的描述，可以包含文本、图片或其他信息。' },
        { title: '培训就业项目入驻', description: '这里是研究生4的描述，可以包含文本、图片或其他信息。' }
      ],
      temploymentindividual: [
        { title: '就业单个项目', description: '这里是研究生1的描述，可以包含文本、图片或其他信息。' },
        { title: '就业单个项目', description: '这里是研究生2的描述，可以包含文本、图片或其他信息。' },
        { title: '就业单个项目', description: '这里是研究生3的描述，可以包含文本、图片或其他信息。' },
        { title: '就业单个项目', description: '这里是研究生4的描述，可以包含文本、图片或其他信息。' }
      ]
    };
  },
  computed: {},
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.contentCards.per_page = val;
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.contentCards.current_page = val;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    initPage() {
      if (this.typeid === '1') {
        Api.Trainingemploymentprojects.getTrainingemploymentprojectsmodel({
          per_page: this.contentCards.per_page,
          current_page: this.contentCards.current_page,
        }).then(res => {
          let data = res.data.data;
          this.contentCards = {
            ...data,
            per_page: parseInt(data.per_page, 10),
            current_page: parseInt(data.current_page, 10),
            total: parseInt(data.total, 10)
          };
        });
      } else if (this.typeid === '2') {
        this.contentCards = this.tdomesticundergraduatecolleges;
      } else if (this.typeid === '3') {
        this.contentCards = this.tenrollmentofforeign;
      } else if (this.typeid === '4') {
        this.contentCards = this.tenrollmentofvocational;
      } else if (this.typeid === '5') {
        this.contentCards = this.tgraduatestudent;
      } else if (this.typeid === '6') {
        this.contentCards = this.toccupationqualification;
      } else if (this.typeid === '7') {
        this.contentCards = this.ttrainingandemployment;
      } else if (this.typeid === '8') {
        this.contentCards = this.temploymentindividual;
      }
    },
    handleNavItemClick(index) {
      this.typeid = index;
      this.initPage();
    },
    handleCardClick(row) {
      console.info(row);
      if (this.typeid === '1') {
        this.$router.push({ path: '/trainingemploymentprojectsdetails', query: { id:row.id} });
      } else if (this.typeid === '2') {

      } else if (this.typeid === '3') {

      } else if (this.typeid === '4') {

      } else if (this.typeid === '5') {

      } else if (this.typeid === '6') {
      } else if (this.typeid === '7') {
      } else if (this.typeid === '8') {
      }
    },
    truncateText(text) {
      if (!text) return '';
      if (text.length <= 10) {
        return text;
      }
      return text.substring(0, 10) + '.....';
    }
  },
  mounted() {
    this.typeid = this.$route.query.typeid || '1'; // 默认值为 '1'
    this.initPage();
  }
};
</script>

<style scoped>
.query-data-page {
  background-color: #f5f5f5;
  min-height: 100vh;
}

/* 顶部横幅样式 */
.header-banner {
  height: 80px;
  background: linear-gradient(to right, #ff7f50, #ff4500);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.header-banner h1 {
  color: white;
  font-size: 28px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

/* 导航栏样式 */
.nav-container {
  background-color: white;
  padding: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
}

.nav-menu-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.nav-menu {
  display: flex;
  justify-content: center;
  width: 100%;
  border-bottom: none;
}

.menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: #333;
  padding: 0 15px;
}

.menu-icon {
  width: 24px;
  height: 24px;
  margin-bottom: 4px;
}

/* 分页按钮样式优化 */
.pagination-button {
  background-color: #f0f0f0;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 10px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pagination-button:hover {
  background-color: #e0e0e0;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.pagination-button:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
  color: #ccc;
  box-shadow: none;
}

.pagination-button i {
  color: #333;
  font-size: 18px;
  transition: color 0.3s ease;
}

.pagination-button:hover i {
  color: #ff4500;
}

/* 内容区域样式 */
.content-area {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
}

.content-card {
  height: 150px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  padding: 15px;
}

.content-card:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.card-content {
  text-align: center;
}

.card-content h3 {
  margin-bottom: 10px;
  color: #333;
}

.card-content p {
  color: #666;
  font-size: 14px;
}

/* 分页样式 */
.pagination-container {
  max-width: 1200px;
  margin: 30px auto;
  display: flex;
  justify-content: center;
}



/* 响应式设计 */
@media (max-width: 768px) {

  .header-banner h1 {
    font-size: 20px;
  }

  .menu-item {
    padding: 0 10px;
  }

  .content-area {
    padding: 10px;
  }

  .content-card {
    height: 120px;
  }
}

@media (max-width: 480px) {
  .header-banner h1 {
    font-size: 16px;
  }

  .menu-item {
    padding: 0 5px;
  }

  .menu-icon {
    width: 20px;
    height: 20px;
  }

  .content-card {
    height: 100px;
  }
}
</style>