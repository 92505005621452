<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="layout header">
      <a href="/" class="logo">
        <img src="../assets/icon/logo1.png" class="logo-img" alt="">
      </a>
      <div class="notice">
        已有账号，
        <router-link to="/login">去登录</router-link>
      </div>
    </div>
    <main class="page">
      <div class="layout content">
        <div class="img-box">
          <img src="../assets/svg/register.svg" class="img" alt="">
        </div>
        <div class="card">
          <el-form :model="formData" :rules="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="邀请码" prop="invite_num">
              <el-input v-model="formData.invite_num" @blur="checkInput()" @input="checkInviteCode"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="mobile">
              <el-input v-model="formData.mobile" @blur="dubotpn()" :disabled="!isInviteCodeFilled"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
              <el-input v-model="formData.code" @input="checkCode">
                <el-button slot="append" :disabled="isDisabled" @click="sendSms()">{{tip}}</el-button>
              </el-input>
              <div v-if="codeMessage" style="color:red; margin-top: 15px;">
                {{ codeMessage }}
              </div>
            </el-form-item>
            <el-form-item label="地区" prop="area_text">
              <el-cascader :options="areaOptions" :disabled="!isInviteCodeFilled" v-model="formData.area_text" clearable></el-cascader>
            </el-form-item>
            <el-form-item label="用户名" prop="username">
              <el-input v-model="formData.username" :disabled="!isInviteCodeFilled"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input type="password" v-model="formData.password" :disabled="!isInviteCodeFilled"></el-input>
            </el-form-item>
            <el-form-item label="" prop="checked">
              <el-checkbox v-model="checked">登录即视为您已阅读并同意<router-link to="/agreement?key=users">注册协议</router-link>、<router-link to="/agreement?key=privacy">隐私政策</router-link></el-checkbox>
            </el-form-item>
            <el-form-item>
              <el-button type="button" class="loginBtn" @click="onSubmit">注册</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Api from "@/Api.js";
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      tip: '获取验证码',
      second: 60,
      isDisabled: false,
      timer: null,
      checked: true,
      areaOptions: [],
      isInviteCodeFilled: false,
      formData: {
        mobile: '',
        username: '',
        invite_num: '',
        password: '',
        code: '',
        area_text: [],
      },
      ruleForm: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
        ],
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        invite_num: [{ required: true, message: '请输入邀请码', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        area_text: [{ required: true, message: '请输入地区', trigger: 'blur' }],
      },
      randomNumber: null,
      codeMessage: '',
    }
  },
  created() {
    this.getAreaOptions();
    this.formData.invite_num = this.$route.query.invite_code;
  },
  methods: {
    ...mapActions(['setToken']),
    checkCode() {
      if (this.formData.code != this.randomNumber) {
        this.codeMessage = '输入错误';
      } else {
        if (this.second == 0) {
          this.codeMessage = '输入错误';
        } else {
          this.codeMessage = '正  确';
        }
      }
    },
    checkInput() {
      if (!this.formData.invite_num.trim()) {
        this.$message.warning('邀请码不能为空');
        this.isInviteCodeFilled = false;
      } else {
        Api.Invitationcode.init({ code: this.formData.invite_num }).then(res => {
          if (res.data.data.total <= 0) {
            this.$message({ showClose: true, message: '请输入正确的邀请码', type: 'warning' });
            this.isInviteCodeFilled = false;
          } else {
            this.$message({ showClose: true, message: 'ok', type: 'success' });
            this.isInviteCodeFilled = true;
          }
        });
      }
    },
    checkInviteCode() {
      this.isInviteCodeFilled = this.formData.invite_num.trim() !== '';
    },
    getAreaOptions() {
      let that = this;
      Api.Config.AreaOptions().then(res => {
        if (res.data.code == 1) {
          that.areaOptions = res.data.data;
        } else {
          that.$message.error(res.data.msg);
        }
      });
    },
    onSubmit() {
      let that = this;
      if (!that.formData.area_text) {
        that.$message.error('请选择所在地');
        return false;
      }
      let area = that.formData.area_text.join('-');
      let parts = area.split("-");
      parts.shift();
      let str = parts.join("-");
      that.formData.area_text = str;
      Api.User.Register(that.formData).then(res => {
        if (res.data.code == 1) {
          that.$store.commit("setToken", res.data.data.userinfo.token);
          that.$store.commit("setLoginStatus", true);
          that.formData.mobile = "";
          that.formData.username = "";
          that.formData.invite_num = "";
          that.formData.code = "";
          that.formData.password = "";
          that.formData.area_text = [];
          that.$router.push({ path: '/' });
        } else {
          that.$message.error(res.data.msg);
        }
      });
    },
    dubotpn() {
      Api.User.getUserBycaptcha({ mobile: this.formData.mobile }).then(res => {
        if (res.data.data.data.length > 0) {
          this.$message({ message: '该手机号已注册,请重新输入!', type: 'warning' });
        }
      });
    },
    sendSms() {
      let that = this;
      if (!that.formData.mobile) {
        that.$message.error('手机号不能为空');
        return false;
      }
      this.randomNumber = Math.floor(10000 + Math.random() * 90000);
      let data = {
        "to": that.formData.mobile,
        "signature": "勋鸿文化",
        "templateId": "pub_verif_register_ttl",
        "templateData": { "code": this.randomNumber, "ttl": "1" }
      };
      $.ajax({
        url: '/sms',
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(data),
        success: (response) => {
          that.btnTimmer();
        },
        error: (error) => {
          this.message = `发送失败：${error.statusText}`;
        }
      });
    },
    btnTimmer() {
      this.timer = setInterval(() => {
        if (this.second == 0) {
          clearInterval(this.timer);
          this.tip = '获取验证码';
          this.isDisabled = false;
          this.second = 60;
        } else {
          this.second--;
          this.tip = this.second + 's重新获取';
          this.isDisabled = true;
        }
      }, 1000);
    },
  },
  computed: {
    ...mapState(['token']),
  },
}
</script>

<style scoped>
/* scoped 让style样式 局部生效*/
.header {
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.logo-img {
  width: 200px;
  height: 100px;
}

.notice {
  color: #333333;
}

.notice a {
  color: #D11A2D;
}

.page {
  min-height: calc(100vh - 240px);
  background: #f7f8fa;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.img-box .img {
  width: 420px;
  height: auto;
}

.card {
  margin-left: 80px;
  background: #ffffff;
  border-radius: 10px;
  width: 500px;
  height: 500px;
  padding: 20px 30px 30px 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 15px;
  border-top: 5px solid #D11A2D;
}

.loginBtn {
  width: 100%;
  background: #D11A2D;
  border-color: #D11A2D;
  color: #ffffff;
}

.loginBtn:active {
  background: #e01e31;
  border-color: #e01e31;
}

.notice {
  font-size: 14px;
  display: inline-block;
  width: 100%;
  text-align: right;
  color: #999999;
}

/deep/ .el-form-item__content {
  line-height: 0 !important;
}

/deep/ .el-form-item {
  margin-bottom: 20px;
}

/deep/ .el-cascader {
  width: 100%;
}
</style>