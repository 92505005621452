<template>
  <main @click="handleOutsideClick" class="page">
    <div class="training-program-page">
      <!-- 顶部横幅 -->
      <div class="header-banner">
        <h1>学历培训项目入驻</h1>
        <p>{{objet.Name}}</p>
      </div>

      <div class="content-wrapper">
        <!-- 侧边栏 -->
        <div class="sidebar">
          <h4>学历培训项目目录</h4>
          <div class="sidebar-item">
            <div class="sidebar-title">报名时间</div>
            <div class="sidebar-date">报名入口</div>
            <div class="sidebar-details">报名条件</div>
          </div>
          <div class="sidebar-item">
            <div class="sidebar-title">项目简介</div>
            <div class="sidebar-date">项目福利</div>
            <div class="sidebar-details">相关文件展示</div>
          </div>
        </div>

        <!-- 主要内容 -->
        <div class="main-content">
          <!-- 适合人群 -->
          <div class="section">
            <div class="section-header">适合人群</div>
            <div class="section-content">
              <ul class="suitable-list">
                <li>{{objet.targetAudience}}</li>
              </ul>
            </div>
          </div>

          <!-- 培训内容 -->
          <div class="section">
            <div class="section-header">培训内容</div>
            <div class="section-content">
              <div class="training-item">学历提升课程</div>
              <div class="training-item">考试辅导</div>
            </div>
          </div>

          <!-- 优惠价格 -->
          <div class="discount-price">
            <div class="price-content">
              <div class="price-text">
                <p>现在报名享受<span class="highlight">优惠价格</span>!</p>
                <p class="price">¥{{ discountPrice }}</p>
                <span class="price-unit">元</span>
              </div>
              <div class="person-image">
                <img src="@/assets/newimage/学历培训项目入驻三级页面.png" alt="人像照片">
              </div>
            </div>
          </div>

          <!-- 项目介绍 -->
          <div class="section">
            <div class="section-header">项目介绍</div>
            <div class="section-content project-intro">
              <p>{{objet.Content}}</p>
              <h2>需要准备材料:</h2>
              <p>{{objet.applicationRequirements}}</p>
              <h2>就业方向:</h2>
              <p>{{objet.employmentDirection}}</p>
              <h2>薪资架构及其福利:</h2>
              <p>{{objet.salaryStructure}}</p>
              <h2>人均收入样表:</h2>
              <div class="income-sample-images">
                <!-- 使用 v-for 遍历图片链接 -->
                <img
                    v-for="(image, index) in incomeSampleImages"
                    :key="index"
                    :src="image"
                    :alt="'收入样表图片 ' + (index + 1)"
                    @click="showImagePreview(image)"
                    @error="handleImageError"
                />
              </div>
              <h2 v-if="objet.schoolfile_path.length > 0">项目相关证明以及相关文件:</h2>
              <div v-if="objet.schoolfile_path.length > 0" class="file-collection">
                <!-- 使用 v-for 遍历文件集合 -->
                <div
                    v-for="(file, index) in objet.schoolfile_path"
                    :key="index"
                    class="file-icon-container"
                    @click="handleFileClick(file)"
                >
                  <img :src="getFileIcon(file.type)" alt="文件图标" class="file-icon">
                </div>
              </div>
            </div>
          </div>
          <!-- 图片预览模态框 -->
          <div v-if="showImagePreviewFlag" class="image-preview-modal">
            <div class="image-preview-content">
              <span class="close-button" @click="closeImagePreview">&times;</span>
              <img :src="previewImage" alt="预览图片" class="preview-image" />
            </div>
          </div>
          <!-- 视频播放模态框 -->
          <div v-if="showVideoPreviewFlag" class="video-preview-modal">
            <div class="video-preview-content">
              <span class="close-button" @click="closeVideoPreview">&times;</span>
              <video controls class="preview-video">
                <source :src="previewVideo" type="video/mp4">
                您的浏览器不支持视频播放。
              </video>
            </div>
          </div>
          <!-- 报名按钮 -->
          <div class="enrollment-button">
            <button @click="handleEnrollment">点击报名</button>
          </div>
        </div>
      </div>
    </div>
    <signup ref="signup" class="popup-container" v-if="showsignup" :visible="showsignup" @close="showsignup = false">
    </signup>
    <!-- 新增的右下角弹窗 -->
    <div v-if="showOnlineInfoPopup" class="online-info-popup">
      <div class="online-info" @mouseenter="showImages = true" @mouseleave="showImages = false">
        线上资讯
      </div>
      <div v-if="showImages" class="info-images">
        <img
            v-for="(image, index) in onlineInfoImages"
            :key="index"
            :src="image"
            alt="资讯图片"
        />
      </div>
    </div>
  </main>
</template>

<script>
import signup from "@/views/ModuleS/signup.vue";
import Api from "@/Api";

export default {
  components: { signup },
  data() {
    return {
      objet: null,
      discountPrice: "1999", // 优惠价格
      showsignup: false,
      defaultImage: "https://via.placeholder.com/200x150?text=No+Image", // 默认占位图
      showImagePreviewFlag: false, // 控制图片预览模态框的显示
      previewImage: "", // 当前预览的图片 URL
      showVideoPreviewFlag: false, // 控制视频播放模态框的显示
      previewVideo: "", // 当前播放的视频 URL
      showOnlineInfoPopup: false, // 控制右下角弹窗的显示
      showImages: false, // 控制资讯图片的显示
      onlineInfoImages: [
        "https://dummyimage.com/200x150/000/fff",
        "https://dummyimage.com/200x150/ff0000/fff",
        "https://dummyimage.com/200x150/00ff00/fff"
      ]
    };
  },
  computed: {
    // 解析收入样表图片链接
    incomeSampleImages() {
      if (!this.objet || !this.objet.incomeSampleimage) return [];
      const images = this.objet.incomeSampleimage.split(",");
      return images.map((image) => {
        // 拼接完整的 URL
        return `https://admin.zhongguojiaoyunet.com/${image.trim()}`;
      });
    },
  },
  methods: {
    handleEnrollment() {
      setTimeout(() => {
        this.showsignup = true;
      }, 1000);
    },
    handleOutsideClick(event) {
      if (this.showsignup) {
        if (!this.$refs.signup?.$el?.contains(event.target)) {
          this.handleClosePopup();
        }
      }
    },
    handleClosePopup() {
      this.$confirm("此操作将关闭弹窗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.$message({
              type: "success",
              message: "关闭!",
            });
            this.showsignup = false;
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
    },
    // 处理图片加载失败
    handleImageError(event) {
      event.target.src = this.defaultImage;
    },
    // 显示图片预览
    showImagePreview(imageUrl) {
      this.previewImage = imageUrl;
      this.showImagePreviewFlag = true;
    },
    // 关闭图片预览
    closeImagePreview() {
      this.showImagePreviewFlag = false;
    },
    // 显示视频播放
    showVideoPreview(videoUrl) {
      this.previewVideo = videoUrl;
      this.showVideoPreviewFlag = true;
    },
    // 关闭视频播放
    closeVideoPreview() {
      this.showVideoPreviewFlag = false;
    },
    // 处理文件点击
    handleFileClick(file) {
      switch (file.type) {
        case "image":
          this.showImagePreview(file.path);
          break;
        case "video":
          this.showVideoPreview(file.path);
          break;
        case "pdf":
        case "document":
          window.open(file.path, "_blank");
          break;
        default:
          window.open(file.path, "_blank");
      }
    },
    // 获取文件图标
    getFileIcon(type) {
      switch (type) {
        case "image":
          return "https://cdn-icons-png.flaticon.com/512/1828/1828884.png";
        case "video":
          return "https://cdn-icons-png.flaticon.com/512/1828/1828861.png";
        case "pdf":
          return "https://cdn-icons-png.flaticon.com/512/1828/1828854.png";
        case "document":
          return "https://cdn-icons-png.flaticon.com/512/1828/1828880.png";
        default:
          return "https://cdn-icons-png.flaticon.com/512/1828/1828884.png";
      }
    }
  },
  mounted() {
    Api.Trainingemploymentprojects.getTrainingemploymentprojectsmodelbyid({
      id: this.$route.query.id || "1",
    }).then((res) => {
      this.objet = res.data.data;
    });
    // 20 秒后显示右下角弹窗
    setTimeout(() => {
      this.showOnlineInfoPopup = true;
    }, 20000);
  },
};
</script>

<style scoped>
.training-program-page {
  background-image: url("@/assets/newimage/学历培训项目入驻三级页面背景图.jpg");
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
  display: flex;
  flex-direction: column;
}

/* 顶部横幅 */
.header-banner {
  background: linear-gradient(45deg, #cc0000, #ff4500);
  color: white;
  text-align: center;
  padding: 40px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}

.header-banner h1 {
  font-size: 40px;
  margin-bottom: 15px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.header-banner p {
  font-size: 22px;
  letter-spacing: 4px;
}

.content-wrapper {
  display: flex;
  flex: 1;
}

/* 侧边栏 */
.sidebar {
  width: 220px;
  background-color: #f4f4f4;
  border-radius: 10px;
  padding: 20px;
  margin: 0 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sidebar h4 {
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.sidebar-item {
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  transition: background-color 0.3s ease;
}

.sidebar-item:hover {
  background-color: #e0e0e0;
}

.sidebar-title {
  font-size: 16px;
  font-weight: bold;
  color: #555;
  margin-bottom: 5px;
}

.sidebar-date {
  font-size: 14px;
  color: #777;
  margin-bottom: 5px;
}

.sidebar-details {
  font-size: 12px;
  color: #999;
}

/* 主要内容 */
.main-content {
  background-color: #ff6600;
  border-radius: 10px;
  margin: 0 20px 20px 0;
  padding: 20px;
  flex: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* 适合人群 */
.section {
  margin-bottom: 20px;
}

.section-header {
  background-color: #cc0000;
  color: white;
  text-align: center;
  padding: 12px;
  border-radius: 25px;
  font-weight: bold;
  margin-bottom: 15px;
}

.section-content {
  background-color: #fff8dc;
  padding: 20px;
  border-radius: 5px;
}

.suitable-list {
  list-style-type: none;
  padding: 0;
}

.suitable-list li {
  padding: 12px 0;
  border-bottom: 1px dashed #ff9933;
}

.suitable-list li:last-child {
  border-bottom: none;
}

/* 培训内容 */
.training-item {
  background-color: #fff8dc;
  border: 1px solid #ff9933;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
}

/* 优惠价格 */
.discount-price {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0;
}

.price-content {
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.price-text {
  flex: 1;
  text-align: left;
  padding-right: 20px;
}

.price-content p {
  font-size: 18px;
  margin: 5px 0;
}

.highlight {
  color: #cc0000;
  font-weight: bold;
}

.price {
  font-size: 28px;
  color: #cc0000;
  font-weight: bold;
}

.price-unit {
  font-size: 20px;
  color: #cc0000;
  font-weight: bold;
}

.person-image {
  width: 400px;
  height: 450px;
  border-radius: 50%;
  overflow: hidden;
}

.person-image img {
  width: 400px;
  height: 450px;
  object-fit: cover;
}

/* 项目介绍 */
.project-intro {
  min-height: 200px;
}

.project-intro p {
  line-height: 1.8;
  font-size: 16px;
}

/* 文件集合 */
.file-collection {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  gap: 15px;
  margin-top: 15px;
}

.file-icon-container {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.file-icon-container:hover {
  transform: scale(1.1);
}

.file-icon {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

/* 报名按钮 */
.enrollment-button {
  text-align: center;
  padding: 30px 0;
}

.enrollment-button button {
  background-color: #cc0000;
  color: white;
  border: none;
  border-radius: 30px;
  padding: 15px 40px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
}

.enrollment-button button:hover {
  background-color: #a30000;
  transform: scale(1.05);
}

/* 图片预览模态框样式 */
.image-preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.image-preview-content {
  position: relative;
  max-width: 80%;
  max-height: 80%;
}

.close-button {
  position: absolute;
  top: -30px;
  right: 0;
  color: white;
  font-size: 30px;
  cursor: pointer;
}

.preview-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
}

/* 视频播放模态框样式 */
.video-preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.video-preview-content {
  position: relative;
  max-width: 80%;
  max-height: 80%;
}

.preview-video {
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
  }

  .sidebar {
    width: auto;
    margin: 15px;
  }

  .header-banner h1 {
    font-size: 24px;
  }

  .header-banner p {
    font-size: 14px;
  }

  .main-content {
    margin: 15px;
  }

  .section-content {
    padding: 15px;
  }

  .suitable-list li {
    padding: 10px 0;
  }

  .price-content p {
    font-size: 16px;
  }

  .price {
    font-size: 24px;
  }

  .price-unit {
    font-size: 18px;
  }

  .enrollment-button button {
    padding: 12px 30px;
    font-size: 16px;
  }

  .person-image {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 480px) {
  .header-banner h1 {
    font-size: 20px;
  }

  .header-banner p {
    font-size: 12px;
  }

  .section-header {
    font-size: 14px;
    padding: 10px;
  }

  .suitable-list li {
    padding: 8px 0;
  }

  .price-content p {
    font-size: 14px;
  }

  .price {
    font-size: 20px;
  }

  .price-unit {
    font-size: 16px;
  }

  .enrollment-button button {
    padding: 10px 25px;
    font-size: 14px;
  }

  .person-image {
    width: 60px;
    height: 60px;
  }
}

/* 新增的右下角弹窗样式 */
.online-info-popup {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.online-info {
  cursor: pointer;
  font-weight: bold;
}

.info-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.info-images img {
  width: 200px;
  height: 150px;
  object-fit: cover;
  border-radius: 5px;
}
</style>