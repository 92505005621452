<template>
  <div class="video-conference-app">
    <!-- 左侧导航栏 -->
    <el-menu class="sidebar" :default-active="activeSection" @select="handleSelect" active-text-color="#409EFF">
      <el-menu-item index="1">
        <i class="el-icon-meeting"></i>
        <span slot="title">会议</span>
      </el-menu-item>
      <el-menu-item index="2">
        <i class="el-icon-contacts"></i>
        <span slot="title">通讯录</span>
      </el-menu-item>
      <el-menu-item index="3">
        <i class="el-icon-video-camera"></i>
        <span slot="title">录制</span>
      </el-menu-item>
    </el-menu>

    <!-- 主内容区域 -->
    <div class="main-content">
      <!-- 会议操作按钮 -->
      <div class="meeting-buttons">
        <el-button type="primary" icon="el-icon-plus" @click="openJoinMeetingDialog">加入会议</el-button>
        <el-button type="primary" icon="el-icon-lightning" @click="openQuickMeetingDialog">快速会议</el-button>
        <el-button type="primary" icon="el-icon-time" @click="scheduleMeeting">预定会议</el-button>
        <el-button type="primary" icon="el-icon-share" @click="shareScreen">共享屏幕</el-button>
      </div>

      <!-- 日期显示 -->
      <div class="date-display">
        <el-date-picker
            v-model="currentDate"
            type="date"
            placeholder="选择日期"
            style="width: 100%"
        >
        </el-date-picker>
      </div>

      <!-- 会议列表 -->
      <div class="meeting-list">
        <h3>全部会议</h3>
        <el-table :data="meetings" style="width: 100%" border>
          <el-table-column prop="name" label="会议名称" width="180"></el-table-column>
          <el-table-column prop="description" label="描述" width="200"></el-table-column>
          <el-table-column prop="start_time" label="开始时间" width="120"></el-table-column>
          <el-table-column prop="end_time" label="结束时间" width="120"></el-table-column>
        </el-table>
      </div>

      <!-- 加入会议弹窗 -->
      <el-dialog
          title="加入会议"
          :visible.sync="joinMeetingDialogVisible"
          width="30%"
          center
      >
        <el-input v-model="meetingId" placeholder="请输入会议记录的ID" class="meeting-id-input"></el-input>
        <div slot="footer" class="dialog-footer">
          <el-button @click="joinMeetingDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="joinMeetingById">确定</el-button>
        </div>
      </el-dialog>

      <!-- 快速会议弹窗 -->
      <el-dialog
          title="快速会议"
          :visible.sync="quickMeetingDialogVisible"
          width="30%"
          center
      >
        <el-form :model="quickMeetingForm" :rules="rules" ref="quickMeetingForm">
          <el-form-item label="会议名称" prop="name">
            <el-input v-model="quickMeetingForm.name" placeholder="请输入会议名称"></el-input>
          </el-form-item>
          <el-form-item label="会议描述" prop="description">
            <el-input type="textarea" v-model="quickMeetingForm.description" placeholder="请输入会议描述"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="quickMeetingDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="handleSubmitQuickMeeting">确定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";

export default {
  data() {
    return {
      activeSection: "1",
      currentDate: new Date(),
      meetings: [
        {
          id: 1,
          name: "团队会议",
          description: "每周团队会议",
          start_time: "09:00",
          end_time: "10:00",
        },
        {
          id: 2,
          name: "项目讨论",
          description: "新项目讨论",
          start_time: "14:00",
          end_time: "15:00",
        },
      ],
      joinMeetingDialogVisible: false, // 控制加入会议弹窗的显示
      meetingId: "", // 用于存储用户输入的会议ID
      quickMeetingDialogVisible: false, // 控制快速会议弹窗的显示
      quickMeetingForm: {
        name: "",
        description: "",
      },
      rules: {
        name: [{ required: true, message: "请输入会议名称", trigger: "blur" }],
        description: [
          { required: true, message: "请输入会议描述", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    handleSelect(key) {
      this.activeSection = key;
    },
    openJoinMeetingDialog() {
      this.joinMeetingDialogVisible = true;
    },
    openQuickMeetingDialog() {
      this.quickMeetingDialogVisible = true;
    },
    joinMeetingById() {
      if (this.meetingId.trim()) {
        Api.Meetings.getbyid({ id: this.meetingId }).then((res) => {
          this.joinMeetingDialogVisible = false;
          this.meetingId = ""; // 清空输入框
          if (res !== null) {
            this.$router.push("/quick-meeting");
          } else {
            console.log("未找到会议");
          }
        });
      } else {
        this.$message.error("请输入会议记录的ID");
      }
    },
    handleSubmitQuickMeeting() {
      this.$refs.quickMeetingForm.validate((valid) => {
        if (valid) {
          const creator_id = this.$store.state.user_info.id;
          const create_time = this.getFormattedDate();
          const modifier_id = this.$store.state.user_info.id;
          const modify_time = this.getFormattedDate();
          const name = this.quickMeetingForm.name;
          const description = this.quickMeetingForm.description;
          const start_time = this.getFormattedDate();
          this.$router.push({ path: '/videosindex', query: { id: 1 } })
          // 调用后端接口创建会议
          // Api.Meetings.inster({
          //   creator_id: creator_id,
          //   create_time: create_time,
          //   modifier_id: modifier_id,
          //   modify_time: modify_time,
          //   name: name,
          //   description: description,
          //   start_time: start_time,
          // })
          //     .then((res) => {
          //       if (res.data.result) {
          //         this.$router.push({ path: '/videosindex', query: { id: res.data.result.id } })
          //       } else {
          //       }
          //       this.quickMeetingDialogVisible = false;
          //       this.quickMeetingForm.name = "";
          //       this.quickMeetingForm.description = "";
          //     })
          //     .catch((err) => {
          //       console.error(err);
          //     });
        } else {
          this.$message.error("请填写完整信息");
          return false;
        }
      });
    },
    getFormattedDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    quickMeeting() {
      this.openQuickMeetingDialog();
    },
    scheduleMeeting() {
      alert("预定会议功能未实现");
    },
    shareScreen() {
      alert("共享屏幕功能未实现");
    },
  },
};
</script>

<style scoped>
.video-conference-app {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 200px;
  background-color: #f8f9fa;
  border-right: 1px solid #dee2e6;
}

.main-content {
  flex: 1;
  padding: 20px;
}

.meeting-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px; /* 添加按钮之间的间距 */
}

.date-display {
  margin-bottom: 20px;
}

.meeting-list {
  margin-top: 20px;
}

.meeting-id-input {
  margin-bottom: 10px;
}

/* 优化表格样式 */
.el-table {
  border: 1px solid #dee2e6;
  border-collapse: collapse;
}

.el-table th,
.el-table td {
  border: 1px solid #dee2e6;
  text-align: center;
}

/* 优化按钮样式 */
.el-button {
  flex: 1;
  margin: 5px;
}

/* 优化弹窗样式 */
.el-dialog {
  border-radius: 8px;
}

.el-dialog__header {
  border-bottom: 1px solid #dee2e6;
}

.el-dialog__footer {
  border-top: 1px solid #dee2e6;
}
</style>