<template>
  <main class="page">
    <meta name="keywords" content="教育,生活,政策,人生规划,院校信息">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">

    <!-- 轮播图 -->
    <div class="banner-slider" >
      <el-carousel :interval="5000" height="500px">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <el-image
              :src="`https://admin.zhongguojiaoyunet.com` + item.url"
              fit="cover"
              style="width: 100%; height: 100%"
          ></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 主内容区域 -->
    <div class="main-content">
      <div class="content-section">
        <h2>地方政策及数据</h2>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="policy-list">
              <h3>
                <span @click="changeActive('政策法规', policyList)" :class="{'active': activeTab === '政策法规'}">政策法规</span>&nbsp;&nbsp;
                <span @click="changeActive('文件法规', documentList)" :class="{'active': activeTab === '文件法规'}">文件法规</span>&nbsp;&nbsp;
                <span @click="changeActive('教育新闻', newsList)" :class="{'active': activeTab === '教育新闻'}">教育新闻</span>&nbsp;&nbsp;
                <span @click="changeActive('教育动态', dynamicList)" :class="{'active': activeTab === '教育动态'}">教育动态</span>&nbsp;&nbsp;
                <span @click="changeActive('教育资源', resourceList)" :class="{'active': activeTab === '教育资源'}">教育资源</span>
              </h3>
              <ul>
                <li v-for="(item, index) in activeContent" :key="index">
                  <a href="#">{{ item.title }}</a>
                  <span class="date">{{ item.date }}</span>
                </li>
              </ul>
              <el-button type="text" @click="pathdatalist" class="more-btn">查看更多 ></el-button>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="policy-list">
              <h3>
                <span @click="changeActive2('公共服务', serviceList)" :class="{'active': activeTab2 === '公共服务'}">公共服务</span>&nbsp;&nbsp;
                <span @click="changeActive2('监督与互动', supervisionList)" :class="{'active': activeTab2 === '监督与互动'}">监督与互动</span>&nbsp;&nbsp;
                <span @click="changeActive2('专项工作', specialWorkList)" :class="{'active': activeTab2 === '专项工作'}">专项工作</span>&nbsp;&nbsp;
                <span @click="changeActive2('数据统计', statisticsList)" :class="{'active': activeTab2 === '数据统计'}">数据统计</span>&nbsp;&nbsp;
                <span @click="changeActive2('特殊教育', specialEduList)" :class="{'active': activeTab2 === '特殊教育'}">特殊教育</span>
              </h3>
              <ul>
                <li v-for="(item, index) in activeContent2" :key="index">
                  <a href="#">{{ item.title }}</a>
                  <span class="date">{{ item.date }}</span>
                </li>
              </ul>
              <el-button type="text" @click="pathdatalist2" class="more-btn">查看更多 ></el-button>
            </div>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="policy-list">
              <h3>
                <span @click="changeActive3('核心政务', coreGovList)" :class="{'active': activeTab3 === '核心政务'}">核心政务</span>&nbsp;&nbsp;
                <span @click="changeActive3('公共事务', publicAffairsList)" :class="{'active': activeTab3 === '公共事务'}">公共事务</span>&nbsp;&nbsp;
                <span @click="changeActive3('办事指南', guideList)" :class="{'active': activeTab3 === '办事指南'}">办事指南</span>&nbsp;&nbsp;
                <span @click="changeActive3('新闻动态', newsDynamicList)" :class="{'active': activeTab3 === '新闻动态'}">新闻动态</span>
              </h3>
              <ul>
                <li v-for="(item, index) in activeContent3" :key="index">
                  <a href="#">{{ item.title }}</a>
                  <span class="date">{{ item.date }}</span>
                </li>
              </ul>
              <el-button type="text" @click="pathdatalist3" class="more-btn">查看更多 ></el-button>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="policy-list">
              <h3>
                <span @click="changeActive4('群体关怀', groupCareList)" :class="{'active': activeTab4 === '群体关怀'}">群体关怀</span>&nbsp;&nbsp;
                <span @click="changeActive4('专项平台', specialPlatformList)" :class="{'active': activeTab4 === '专项平台'}">专项平台</span>&nbsp;&nbsp;
                <span @click="changeActive4('数据库', dbList)" :class="{'active': activeTab4 === '数据库'}">数据库</span>&nbsp;&nbsp;
                <span @click="changeActive4('互动交流', interactionList)" :class="{'active': activeTab4 === '互动交流'}">互动交流</span>&nbsp;&nbsp;
                <span @click="changeActive4('公众参与', publicParticipationList)" :class="{'active': activeTab4 === '公众参与'}">公众参与</span>
              </h3>
              <ul>
                <li v-for="(item, index) in activeContent4" :key="index">
                  <a href="#">{{ item.title }}</a>
                  <span class="date">{{ item.date }}</span>
                </li>
              </ul>
              <el-button type="text" @click="pathdatalist4" class="more-btn">查看更多 ></el-button>
            </div>
          </el-col>
        </el-row>
      </div>

      <!-- 查询数据 -->
      <div class="content-section">
        <h2>查询数据</h2>
        <el-row :gutter="20">
          <el-col :span="8" v-for="(item, index) in serviceList1" :key="index">
            <div class="service-card" :style="{ background: item.bgColor }">
              <div class="service-icon">
                <img :src="item.icon" alt="icon" loading="lazy" />
              </div>
              <div class="service-title">{{ item.title }}</div>
              <div class="service-desc">{{ item.desc }}</div>
            </div>
          </el-col>
        </el-row>
      </div>

      <!-- 实时热点 -->
      <div class="content-section">
        <h2>实时热点</h2>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="hot-section">
              <div style="display: flex; justify-content: space-between; align-items: center;">
                <h3>热点赛事及要闻</h3>
                <el-button type="text" @click="pathdatalist5()" class="more-btn">查看更多 ></el-button>
              </div>
              <div class="hot-scroll">
                <div class="hot-content" :style="{ height: scrollHeight + 'px' }">
                  <div v-for="(item, index) in hotCompetitionsAndNews" :key="index" class="hot-item">
                    <div class="charge-card" :style="{ background: item.bgColor }">
                      <div class="charge-title">{{ item.title }}</div>
                      <div class="charge-icon">
                        <img :src="item.icon" alt="icon" loading="lazy" />
                      </div>
                      <div class="charge-desc">{{ item.desc }}</div>
                    </div>
                  </div>
                  <!-- 复制三份内容用于循环滚动 -->
                  <div v-for="(item, index) in hotCompetitionsAndNews" :key="'copy1-' + index" class="hot-item">
                    <div class="charge-card" :style="{ background: item.bgColor }">
                      <div class="charge-title">{{ item.title }}</div>
                      <div class="charge-icon">
                        <img :src="item.icon" alt="icon" loading="lazy" />
                      </div>
                      <div class="charge-desc">{{ item.desc }}</div>
                    </div>
                  </div>
                  <div v-for="(item, index) in hotCompetitionsAndNews" :key="'copy2-' + index" class="hot-item">
                    <div class="charge-card" :style="{ background: item.bgColor }">
                      <div class="charge-title">{{ item.title }}</div>
                      <div class="charge-icon">
                        <img :src="item.icon" alt="icon" loading="lazy" />
                      </div>
                      <div class="charge-desc">{{ item.desc }}</div>
                    </div>
                  </div>
                  <div v-for="(item, index) in hotCompetitionsAndNews" :key="'copy3-' + index" class="hot-item">
                    <div class="charge-card" :style="{ background: item.bgColor }">
                      <div class="charge-title">{{ item.title }}</div>
                      <div class="charge-icon">
                        <img :src="item.icon" alt="icon" loading="lazy" />
                      </div>
                      <div class="charge-desc">{{ item.desc }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="hot-section">
              <div style="display: flex; justify-content: space-between; align-items: center;">
                <h3>热门校企实况</h3>
                <el-button type="text" @click="pathdatalist6()" class="more-btn">查看更多 ></el-button>
              </div>
              <div class="hot-scroll">
                <div class="hot-content" :style="{ height: scrollHeight + 'px' }">
                  <div v-for="(item, index) in hotSchoolEnterprise" :key="index" class="hot-item">
                    <div class="charge-card" :style="{ background: item.bgColor }">
                      <div class="charge-title">{{ item.title }}</div>
                      <div class="charge-icon">
                        <img :src="item.icon" alt="icon" loading="lazy" />
                      </div>
                      <div class="charge-desc">{{ item.desc }}</div>
                    </div>
                  </div>
                  <!-- 复制三份内容用于循环滚动 -->
                  <div v-for="(item, index) in hotSchoolEnterprise" :key="'copy1-' + index" class="hot-item">
                    <div class="charge-card" :style="{ background: item.bgColor }">
                      <div class="charge-title">{{ item.title }}</div>
                      <div class="charge-icon">
                        <img :src="item.icon" alt="icon" loading="lazy" />
                      </div>
                      <div class="charge-desc">{{ item.desc }}</div>
                    </div>
                  </div>
                  <div v-for="(item, index) in hotSchoolEnterprise" :key="'copy2-' + index" class="hot-item">
                    <div class="charge-card" :style="{ background: item.bgColor }">
                      <div class="charge-title">{{ item.title }}</div>
                      <div class="charge-icon">
                        <img :src="item.icon" alt="icon" loading="lazy" />
                      </div>
                      <div class="charge-desc">{{ item.desc }}</div>
                    </div>
                  </div>
                  <div v-for="(item, index) in hotSchoolEnterprise" :key="'copy3-' + index" class="hot-item">
                    <div class="charge-card" :style="{ background: item.bgColor }">
                      <div class="charge-title">{{ item.title }}</div>
                      <div class="charge-icon">
                        <img :src="item.icon" alt="icon" loading="lazy" />
                      </div>
                      <div class="charge-desc">{{ item.desc }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <!-- 收费项目 -->
      <div class="content-section">
        <h2>收费项目</h2>
        <el-row :gutter="20">
          <el-col :span="8" v-for="(item, index) in chargeList" :key="index">
            <div class="charge-card" :style="{ background: item.bgColor }">
              <div class="charge-title">{{ item.title }}</div>
              <div class="charge-icon">
                <img :src="item.icon" alt="icon" loading="lazy" />
              </div>
              <div class="charge-desc">{{ item.desc }}</div>
            </div>
          </el-col>
        </el-row>
      </div>

      <!-- 精选直播课程 -->
      <div class="content-section">
        <h2>精选直播课程</h2>
        <el-row :gutter="20">
          <el-col :span="6" v-for="(item, index) in courseList" :key="index">
            <div class="course-card">
              <div class="course-img">
                <img :src="item.img" alt="course" loading="lazy" />
              </div>
              <div class="course-title">{{ item.title }}</div>
              <div class="course-info">{{ item.info }}</div>
              <div class="course-meta">
                <span class="course-time">{{ item.time }}</span>
                <span class="course-view">{{ item.view }}</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <!-- 友情链接 -->
      <div class="content-section">
        <h2>
          友情链接
          <el-button type="text" @click="toggleLinks" class="toggle-btn">
            {{ linksVisible ? '收起' : '展开' }}
          </el-button>
        </h2>
        <div class="link-list" v-if="linksVisible">
          <ul>
            <li v-for="(link, index) in links" :key="index">
              <a :href="link.url">{{ link.name }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 底部区域 -->
    <footer class="footer-container">
      <div class="footer-content">
        <div class="footer-section">
          <h3>
            <el-image style="width: 40px;height: 40px" :src="require('@/assets/newimage/iocn1.png')"></el-image>
            汇聚全国项目优质项目,保障安全
          </h3>
          <div class="footerlink-list">
            <ul>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
            </ul>
          </div>
        </div>
        <div class="footer-section">
          <h3>
            <el-image style="width: 40px;height: 40px" :src="require('@/assets/newimage/iocn2.png')"></el-image>
            打造终身学习平台
          </h3>
          <div class="footerlink-list">
            <ul>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
            </ul>
          </div>
        </div>
        <div class="footer-section">
          <h3>
            <el-image style="width: 40px;height: 40px" :src="require('@/assets/newimage/icon3.png')"></el-image>
            职业技能培训就业安排
          </h3>
          <div class="footerlink-list">
            <ul>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </main>
</template>

<script>
import Api from "@/Api";

export default {
  data() {
    return {
      linksVisible: false,
      bannerList: [],
      policyList: [],
      documentList: [
        {title: '关于推进教育信息化2.0行动计划', date: '2023-05-10'},
        {title: '关于加强新时代教育研究工作的意见', date: '2023-04-15'},
        {title: '关于做好2023年普通高校招生工作的通知', date: '2023-03-25'}
      ],
      newsList: [
        {title: '教育新闻标题1', date: '2023-06-01'},
        {title: '教育新闻标题2', date: '2023-05-30'},
        {title: '教育新闻标题3', date: '2023-05-28'}
      ],
      dynamicList: [
        {title: '教育动态标题1', date: '2023-06-02'},
        {title: '教育动态标题2', date: '2023-05-29'},
        {title: '教育动态标题3', date: '2023-05-26'}
      ],
      resourceList: [
        {title: '教育资源标题1', date: '2023-06-03'},
        {title: '教育资源标题2', date: '2023-05-30'},
        {title: '教育资源标题3', date: '2023-05-27'}
      ],
      serviceList: [
        {title: '公共服务标题1', date: '2023-06-04'},
        {title: '公共服务标题2', date: '2023-05-31'},
        {title: '公共服务标题3', date: '2023-05-28'}
      ],
      supervisionList: [
        {title: '监督与互动标题1', date: '2023-06-05'},
        {title: '监督与互动标题2', date: '2023-05-32'},
        {title: '监督与互动标题3', date: '2023-05-29'}
      ],
      specialWorkList: [
        {title: '专项工作标题1', date: '2023-06-06'},
        {title: '专项工作标题2', date: '2023-05-33'},
        {title: '专项工作标题3', date: '2023-05-30'}
      ],
      statisticsList: [
        {title: '数据统计标题1', date: '2023-06-07'},
        {title: '数据统计标题2', date: '2023-05-34'},
        {title: '数据统计标题3', date: '2023-05-31'}
      ],
      specialEduList: [
        {title: '特殊教育标题1', date: '2023-06-08'},
        {title: '特殊教育标题2', date: '2023-05-35'},
        {title: '特殊教育标题3', date: '2023-05-32'}
      ],
      coreGovList: [
        {title: '核心政务标题1', date: '2023-06-09'},
        {title: '核心政务标题2', date: '2023-05-36'},
        {title: '核心政务标题3', date: '2023-05-33'}
      ],
      publicAffairsList: [
        {title: '公共事务标题1', date: '2023-06-10'},
        {title: '公共事务标题2', date: '2023-05-37'},
        {title: '公共事务标题3', date: '2023-05-34'}
      ],
      guideList: [
        {title: '办事指南标题1', date: '2023-06-11'},
        {title: '办事指南标题2', date: '2023-05-38'},
        {title: '办事指南标题3', date: '2023-05-35'}
      ],
      newsDynamicList: [
        {title: '新闻动态标题1', date: '2023-06-12'},
        {title: '新闻动态标题2', date: '2023-05-39'},
        {title: '新闻动态标题3', date: '2023-05-36'}
      ],
      groupCareList: [
        {title: '群体关怀标题1', date: '2023-06-13'},
        {title: '群体关怀标题2', date: '2023-05-40'},
        {title: '群体关怀标题3', date: '2023-05-37'}
      ],
      specialPlatformList: [
        {title: '专项平台标题1', date: '2023-06-14'},
        {title: '专项平台标题2', date: '2023-05-41'},
        {title: '专项平台标题3', date: '2023-05-38'}
      ],
      dbList: [
        {title: '数据库标题1', date: '2023-06-15'},
        {title: '数据库标题2', date: '2023-05-42'},
        {title: '数据库标题3', date: '2023-05-39'}
      ],
      interactionList: [
        {title: '互动交流标题1', date: '2023-06-16'},
        {title: '互动交流标题2', date: '2023-05-43'},
        {title: '互动交流标题3', date: '2023-05-40'}
      ],
      publicParticipationList: [
        {title: '公众参与标题1', date: '2023-06-17'},
        {title: '公众参与标题2', date: '2023-05-44'},
        {title: '公众参与标题3', date: '2023-05-41'}
      ],
      activeTab: '政策法规',
      activeContent: [],
      activeTab2: '公共服务',
      activeContent2: [],
      activeTab3: '核心政务',
      activeContent3: [],
      activeTab4: '群体关怀',
      activeContent4: [],
      serviceList1: [
        {
          title: '学校详情与学科展示',
          desc: '查询全国高校及学科信息',
          bgColor: '#54b8d8',
          icon: require('@/assets/newimage/学校详情与学科展示.png')
        },
        {
          title: '教育展览与赛事',
          desc: '各类教育展览和赛事信息',
          bgColor: '#5cd885',
          icon: require('@/assets/newimage/教育展览与赛事.png')
        },
        {
          title: '智慧教育与公共服务',
          desc: '智慧教育与公共服务',
          bgColor: '#a57bd8',
          icon: require('@/assets/newimage/教育理论与学术展览.png')
        },
        {
          title: '学术期刊与资讯',
          desc: '学术期刊与资讯',
          bgColor: '#e87463',
          icon: require('@/assets/newimage/教育应急与危机管理.png')
        },
        {
          title: '教育领域前沿动态',
          desc: '教育领域前沿动态',
          bgColor: '#ffd874',
          icon: require('@/assets/newimage/教育领域前沿动态.png')
        },
        {
          title: '基础服务查询',
          desc: '基础服务查询',
          bgColor: '#54b8d8',
          icon: require('@/assets/newimage/学校详情与学科展示.png')
        },
      ],
      chargeList: [
        {
          title: '学历培训',
          desc: '学历培训资讯项目',
          bgColor: '#f87463',
          icon: require('@/assets/newimage/国际交流与合作.png')
        },
        {
          title: '国内中大专本科',
          desc: '国内中大专本科项目',
          bgColor: '#74f8a6',
          icon: require('@/assets/newimage/中外合作办学信息查询.png')
        },
        {
          title: '国外院校',
          desc: '国外院校资讯项目',
          bgColor: '#f8d074',
          icon: require('@/assets/newimage/招生考试与学历服务.png')
        },
        {
          title: '专科本科项目',
          desc: '专科本科项目服务',
          bgColor: '#74d0f8',
          icon: require('@/assets/newimage/国际学生服务.png')
        },
        {
          title: '研究生项目资讯',
          desc: '研究生项目资讯信息',
          bgColor: '#74a6f8',
          icon: require('@/assets/newimage/职业教育与继续教育.png')
        },
        {
          title: '职业资格证书',
          desc: '职业资格证书认证服务',
          bgColor: '#a674f8',
          icon: require('@/assets/newimage/国际教育认证与合作.png')
        },
        {
          title: '培训就业项目',
          desc: '培训就业项目资讯信息',
          bgColor: '#74f8d0',
          icon: require('@/assets/newimage/教育援助及公益项目.png')
        },
        {
          title: '就业项目',
          desc: '就业项目资讯信息',
          bgColor: '#d074f8',
          icon: require('@/assets/newimage/地方教育合作与对口支持.png')
        }
      ],
      courseList: [
        {
          title: '如何找到适合的专业和职业方向',
          info: '职业规划专家：张教授',
          time: '2023-06-15',
          view: '3.2k',
          img: require('@/assets/newimage/直播课程模版.jpg')
        },
        {
          title: '跟着"尚教授"学财务',
          info: '主讲人：尚教授',
          time: '2023-06-10',
          view: '5.6k',
          img: require('@/assets/newimage/直播课程模版.jpg')
        },
        {
          title: '跟着宁教授学公司治理',
          info: '主讲人：宁教授',
          time: '2023-06-05',
          view: '4.8k',
          img: require('@/assets/newimage/直播课程模版.jpg')
        },
        {
          title: '商界论道：中国经济发展新趋势',
          info: '主讲人：李教授',
          time: '2023-05-30',
          view: '2.9k',
          img: require('@/assets/newimage/直播课程模版.jpg')
        },
        {
          title: '反垄断法解读',
          info: '主讲人：王律师',
          time: '2023-05-25',
          view: '1.8k',
          img: require('@/assets/newimage/直播课程模版.jpg')
        },
        {
          title: '财务分析与决策',
          info: '主讲人：陈教授',
          time: '2023-05-20',
          view: '2.4k',
          img: require('@/assets/newimage/直播课程模版.jpg')
        },
        {
          title: '营销：人人需掌握的一门课',
          info: '主讲人：刘教授',
          time: '2023-05-15',
          view: '3.1k',
          img: require('@/assets/newimage/直播课程模版.jpg')
        },
        {
          title: '心理学导论',
          info: '主讲人：张教授',
          time: '2023-05-10',
          view: '4.2k',
          img: require('@/assets/newimage/直播课程模版.jpg')
        }
      ],
      links: [],
      hotCompetitionsAndNews: [
        {
          title: '全国大学生创新创业大赛',
          desc: '全国性创新创业赛事，汇聚高校优秀项目',
          bgColor: '#ff0000',
          icon: require('@/assets/newimage/学校详情与学科展示.png')
        },
        {
          title: '全国大学生创新创业大赛',
          desc: '全国性创新创业赛事，汇聚高校优秀项目',
          bgColor: '#ff0000',
          icon: require('@/assets/newimage/学校详情与学科展示.png')
        },
        {
          title: '全国大学生创新创业大赛',
          desc: '全国性创新创业赛事，汇聚高校优秀项目',
          bgColor: '#ff0000',
          icon: require('@/assets/newimage/学校详情与学科展示.png')
        },
        {
          title: '全国大学生创新创业大赛',
          desc: '全国性创新创业赛事，汇聚高校优秀项目',
          bgColor: '#ff0000',
          icon: require('@/assets/newimage/学校详情与学科展示.png')
        }
      ],
      hotSchoolEnterprise: [
        {
          title: '某高校与企业合作开展科研项目',
          desc: '高校与企业联合科研，推动产学研融合',
          bgColor: '#00b1ff',
          icon: require('@/assets/newimage/学校详情与学科展示.png')
        },
        {
          title: '某高校与企业合作开展科研项目',
          desc: '高校与企业联合科研，推动产学研融合',
          bgColor: '#00b1ff',
          icon: require('@/assets/newimage/学校详情与学科展示.png')
        },
        {
          title: '某高校与企业合作开展科研项目',
          desc: '高校与企业联合科研，推动产学研融合',
          bgColor: '#00b1ff',
          icon: require('@/assets/newimage/学校详情与学科展示.png')
        },
        {
          title: '某高校与企业合作开展科研项目',
          desc: '高校与企业联合科研，推动产学研融合',
          bgColor: '#00b1ff',
          icon: require('@/assets/newimage/学校详情与学科展示.png')
        },
        {
          title: '某高校与企业合作开展科研项目',
          desc: '高校与企业联合科研，推动产学研融合',
          bgColor: '#00b1ff',
          icon: require('@/assets/newimage/学校详情与学科展示.png')
        },
        {
          title: '某高校与企业合作开展科研项目',
          desc: '高校与企业联合科研，推动产学研融合',
          bgColor: '#00b1ff',
          icon: require('@/assets/newimage/学校详情与学科展示.png')
        },
        {
          title: '某高校与企业合作开展科研项目',
          desc: '高校与企业联合科研，推动产学研融合',
          bgColor: '#00b1ff',
          icon: require('@/assets/newimage/学校详情与学科展示.png')
        },
        {
          title: '某高校与企业合作开展科研项目',
          desc: '高校与企业联合科研，推动产学研融合',
          bgColor: '#00b1ff',
          icon: require('@/assets/newimage/学校详情与学科展示.png')
        }
      ],
      scrollHeight: 0,
    };
  },
  computed: {
    myState: {
      get() {
        return this.$store.getters.myState;
      }
    }
  },
  watch: {
    myState(newValue) {
      this.renew(newValue[1], newValue[2], newValue[4]);
    }
  },
  mounted() {
    if (this.$store.state.LocationPCity[0] != null) {
      this.renew(this.$store.state.LocationPCity[1], this.$store.state.LocationPCity[2], this.$store.state.LocationPCity[4]);
    }
    this.handleResize();
    this.$nextTick(() => {
      this.scrollHeight = document.querySelector('.hot-scroll')?.clientHeight || 300;
    });
    window.addEventListener('resize', this.handleResize);
    Api.Documentregulations.indexALL({

    }).then(res=>{
      this.documentList = res.data.DocumentregulationsModelQ.data;
      // 教育新闻
      this.newsList = res.data.EducationalnewsModel.data;
      // 教育动态
      this.dynamicList = res.data.EducationdynamicsQ.data;
      // 教育资源
      this.resourceList = res.data.EducationalresourcesModelQ.data;
      // 监督与互动
      this.supervisionList = res.data.Supervisioninteraction.data;
      // 专项工作
      this.specialWorkList = res.data.Specialprojects.data;
      // 公共服务
      this.serviceList = res.data.Publicservices.data;
      // 数据统计
      this.statisticsList = res.data.Datastatistics.data;
      // 特殊教育
      this.specialEduList = res.data.Specialeducation.data;
      // 公共事务
      this.publicAffairsList = res.data.Publicaffairs.data;
      // 核心政务
      this.coreGovList = res.data.Coregovernmentaffairs.data;
      // 办事指南
      this.guideList = res.data.Serviceguide.data;
      // 新闻动态
      this.newsDynamicList = res.data.Newsinformation.data;
      // 群体关怀
      this.groupCareList = res.data.Groupcare.data;
      // 专项平台
      this.specialPlatformList = res.data.Specialplatform.data;
      // 数据库
      this.dbList = res.data.Database.data;
      // 互动交流
      this.interactionList = res.data.Interactivecommunication.data;
      // 公众参与
      this.publicParticipationList = res.data.Citizenparticipation.data;

      // 初始化标签页内容
      this.activeContent2 = this.serviceList;
      this.activeContent3 = this.coreGovList;
      this.activeContent4 = this.groupCareList;
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      const screenWidth = document.body.clientWidth;
      if (screenWidth < 768) {
        document.documentElement.style.fontSize = `${screenWidth / 3.75}px`;
      } else {
        document.documentElement.style.fontSize = '100px';
      }
    },
    changeActive(tab, content) {
      this.activeTab = tab;
      this.activeContent = content;
    },
    changeActive2(tab, content) {
      this.activeTab2 = tab;
      this.activeContent2 = content;
    },
    changeActive3(tab, content) {
      this.activeTab3 = tab;
      this.activeContent3 = content;
    },
    changeActive4(tab, content) {
      this.activeTab4 = tab;
      this.activeContent4 = content;
    },
    renew(newValue, newcity, judge) {
      const promise1 = Api.Config.GetInit({
        areaid: newValue,
        city: newcity,
        judge: judge,
      });

      const promise2 = Api.Nationalpolicy.newinitialization({
        provinceareaids: newValue
      });

      Promise.all([promise1, promise2])
          .then(([res1, res2]) => {
            this.bannerList = res1.data.data.banner;
            this.links = res1.data.data.friends;
            this.policyList = res2.data.Ndata.data;
            this.activeContent = this.policyList;
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
    },
    pathdatalist() {
      if (this.activeTab === "政策法规") {
        this.$router.push({path: '/datalist', query: {typeid: 1}});
      } else if (this.activeTab === "文件法规") {
        this.$router.push({path: '/datalist', query: {typeid: 2}});
      } else if (this.activeTab === "教育新闻") {
        this.$router.push({path: '/datalist', query: {typeid: 3}});
      } else if (this.activeTab === "教育动态") {
        this.$router.push({path: '/datalist', query: {typeid: 4}});
      } else if (this.activeTab === "教育资源") {
        this.$router.push({path: '/datalist', query: {typeid: 5}});
      }
    },
    pathdatalist2() {
      if (this.activeTab2 === "公共服务") {
        this.$router.push({path: '/datalist', query: {typeid: 6}});
      } else if (this.activeTab2 === "监督与互动") {
        this.$router.push({path: '/datalist', query: {typeid: 7}});
      } else if (this.activeTab2 === "专项工作") {
        this.$router.push({path: '/datalist', query: {typeid: 8}});
      } else if (this.activeTab2 === "数据统计") {
        this.$router.push({path: '/datalist', query: {typeid: 9}});
      } else if (this.activeTab2 === "特殊教育") {
        this.$router.push({path: '/datalist', query: {typeid: 10}});
      }
    },
    pathdatalist3() {
      if (this.activeTab3 === "核心政务") {
        this.$router.push({path: '/datalist', query: {typeid: 11}});
      } else if (this.activeTab3 === "公共事务") {
        this.$router.push({path: '/datalist', query: {typeid: 12}});
      } else if (this.activeTab3 === "办事指南") {
        this.$router.push({path: '/datalist', query: {typeid: 13}});
      } else if (this.activeTab3 === "新闻动态") {
        this.$router.push({path: '/datalist', query: {typeid: 14}});
      }
    },
    pathdatalist4() {
      if (this.activeTab4 === "群体关怀") {
        this.$router.push({path: '/datalist', query: {typeid: 15}});
      } else if (this.activeTab4 === "专项平台") {
        this.$router.push({path: '/datalist', query: {typeid: 16}});
      } else if (this.activeTab4 === "数据库") {
        this.$router.push({path: '/datalist', query: {typeid: 17}});
      } else if (this.activeTab4 === "互动交流") {
        this.$router.push({path: '/datalist', query: {typeid: 18}});
      } else if (this.activeTab4 === "公众参与") {
        this.$router.push({path: '/datalist', query: {typeid: 19}});
      }
    },
    pathdatalist5() {
      this.$router.push({path: '/datalist', query: {typeid: 20}});
    },
    pathdatalist6() {
      this.$router.push({path: '/datalist', query: {typeid: 21}});
    },
    handleCardClick(row) {
      if (row.title === "学校详情与学科展示") {
        this.$router.push({path: '/schools'});
      } else if (row.title === "学历培训") {
        this.$router.push({path: '/newdatalist', query: {typeid: 1}});
      } else if (row.title === "国内中大专本科") {
        this.$router.push({path: '/newdatalist', query: {typeid: 2}});
      } else if (row.title === "国外院校") {
        this.$router.push({path: '/newdatalist', query: {typeid: 2}});
      } else if (row.title === "专科本科项目") {
        this.$router.push({path: '/newdatalist', query: {typeid: 3}});
      } else if (row.title === "研究生项目资讯") {
        this.$router.push({path: '/newdatalist', query: {typeid: 4}});
      } else if (row.title === "职业资格证书") {
        this.$router.push({path: '/newdatalist', query: {typeid: 5}});
      } else if (row.title === "培训就业项目") {
        this.$router.push({path: '/newdatalist', query: {typeid: 6}});
      } else if (row.title === "就业项目") {
        this.$router.push({path: '/newdatalist', query: {typeid: 7}});
      }
    },
    toggleLinks() {
      this.linksVisible = !this.linksVisible;
    },
  }
};
</script>

<style scoped>
.banner-slider {
  position: relative;
  width: 80%;
  max-width: 1200px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.el-carousel {
  width: 100%;
  height: 500px;
}

.el-carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-content {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.content-section {
  margin-bottom: 30px;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.content-section h2 {
  font-size: 18px;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
  margin-bottom: 20px;
  color: #333;
}

.policy-list {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 4px;
}

.policy-list h3 {
  font-size: 16px;
  margin-bottom: 15px;
  color: #ff6600;
}

.policy-list h3 span {
  color: #333;
  text-decoration: none;
  padding-bottom: 5px;
  cursor: pointer;
  transition: all 0.3s;
}

.policy-list h3 span:hover {
  color: #ff6600;
  border-bottom: 2px solid #ff6600;
}

.policy-list h3 span.active {
  color: #ff6600;
  border-bottom: 2px solid #ff6600;
}

.policy-list ul {
  list-style: none;
  padding: 0;
}

.policy-list li {
  padding: 8px 0;
  border-bottom: 1px dashed #eee;
  display: flex;
  justify-content: space-between;
}

.policy-list li a {
  color: #333;
  text-decoration: none;
}

.policy-list li a:hover {
  color: #ff6600;
}

.date {
  color: #999;
  font-size: 12px;
}

.more-btn {
  display: block;
  text-align: right;
  margin-top: 15px;
  color: #ff6600;
}

.service-card {
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.service-icon {
  width: 60px;
  height: 60px;
  margin: 0 auto 15px;
}

.service-icon img {
  width: 100%;
  height: 100%;
}

.service-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
}

.service-desc {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
}

.charge-card {
  padding: 25px;
  border-radius: 4px;
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
}

.charge-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.charge-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #fff;
}

.charge-icon {
  width: 80px;
  height: 80px;
  margin: 0 auto 15px;
}

.charge-icon img {
  width: 100%;
  height: 100%;
}

.charge-desc {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
}

.course-card {
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
}

.course-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.course-img {
  height: 120px;
  overflow: hidden;
}

.course-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.course-title {
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
  margin: 0;
}

.course-info {
  font-size: 12px;
  color: #999;
  padding: 0 10px 10px;
}

.course-meta {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-top: 1px solid #eee;
  font-size: 12px;
  color: #999;
}

.link-list ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.link-list li {
  flex: 0 0 25%;
  margin-bottom: 10px;
}

.link-list a {
  color: #666;
  text-decoration: none;
  font-size: 14px;
}

.link-list a:hover {
  color: #ff6600;
}

.footer-container {
  height: 400px;
  background-color: #4a4a66;
  padding: 40px 0 20px;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-section {
  height: 220px;
  flex: 1;
  min-width: 200px;
  margin-right: 30px;
  margin-top: 200px;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
}

.footer-section:last-child {
  margin-right: 0;
}

.footer-section h3 {
  font-size: 16px;
  margin-bottom: 20px;
  color: #333;
}

.footer-section ul {
  padding: 0;
}

.footer-section li {
  margin-bottom: 10px;
}

.footer-section a {
  color: #666;
  text-decoration: none;
  font-size: 14px;
}

.footer-section a:hover {
  color: #ff6600;
}

.page {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  position: relative;
}

/* 实时热点样式 */
.hot-section {
  margin-bottom: 20px;
}

.hot-section h3 {
  font-size: 16px;
  margin-bottom: 15px;
  color: #ff6600;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.hot-scroll {
  position: relative;
  height: 300px;
  overflow: hidden;
}

.hot-content {
  position: absolute;
  width: 100%;
  animation: scrollAnimation 40s cubic-bezier(0.36, 0.08, 0.23, 0.96) infinite;
}

.hot-item {
  margin-bottom: 20px;
}

.toggle-btn {
  margin-left: 10px;
  color: #ff6600;
  font-size: 14px;
}

@keyframes scrollAnimation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

/* 响应式设计 */
@media (max-width: 768px) {
  .main-content {
    padding: 10px;
  }

  .content-section {
    padding: 15px;
  }

  .el-col {
    width: 100%;
    margin-bottom: 15px;
  }

  .banner-slider {
    position: relative;
    width: 80%;
    max-width: 88%;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
  }

  .footer-links {
    flex-direction: column;
  }

  .link-group {
    margin-bottom: 20px;
  }

  .policy-list h3 {
    font-size: 14px;
  }

  .policy-list li {
    font-size: 12px;
  }

  .service-card, .charge-card {
    padding: 15px;
  }

  .service-title, .charge-title {
    font-size: 14px;
  }

  .service-desc, .charge-desc {
    font-size: 12px;
  }

  .course-card {
    margin-bottom: 10px;
  }

  .course-title {
    font-size: 12px;
  }

  .course-info, .course-meta {
    font-size: 10px;
  }

  .el-carousel {
    height: 150px !important;
  }

  .course-img {
    height: 100px;
  }

  .course-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/* 实时热点样式 */
.hot-section {
  margin-bottom: 20px;
}

.hot-section h3 {
  font-size: 16px;
  margin-bottom: 15px;
  color: #ff6600;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
</style>